.container {
  display: block;
  width: 100%;
  height: 100%;
  padding: 15px;

  .overtime {
    display: flex;
    &__info {
      flex-basis: 70%;

      .info__grid {
        display: grid;
        grid-template-columns: 45% 45%;

        .employee__name,
        .employee__department {
          font-size: 16px;
          font-weight: 600;

          margin: 15px 0px;
        }

        .date-from {
          display: flex;
          align-items: center;
        }

        .date-to {
          display: flex;
          align-items: center;
        }

        .date-from,
        .date-to {
          text-align: center;
          font-size: 16px;
          font-weight: 600;
        }

        .date-title {
          padding-right: 15px;
        }
      }

      &--date-picker {
        display: flex;
      }
    }

    &__totaltime {
      flex-basis: 45%;

      display: inline;
      grid-template-columns: 50% 50%;
      padding-top: 15px;

      font-size: 18px;
      font-weight: 600;
      text-align: center;

      > div {
        background-color: var(--bs-gray-300);
        box-shadow: 2px 2px 5px rgba(128, 128, 128, 0.637);
      }

      .text-title {
        background-color: var(--bs-gray-300);
        box-shadow: 2px 2px 5px rgba(128, 128, 128, 0.637);
        display: flex;
        width: 100%;
      }

      .text-label {
        background-color: var(--bs-gray-300);
        box-shadow: 2px 2px 5px rgba(128, 128, 128, 0.637);
        display: flex;
        width: 100%;
        margin-bottom: 5px;
        padding: 10px 0px;

        border-right: 1px solid rgba(128, 128, 128, 0.637);
      }
      .text-number {
        display: flex;
        width: 100%;
      }

      .text-center {
        flex: 50%;

        display: flex;
        align-items: center;
        justify-content: center;
      }

      &--hours {
        flex-basis: 50%;
        font-size: 45px;
        font-weight: 600;
        color: var(--bs-stratos);
        text-shadow: 1px 2px rgba(173, 173, 173, 0.349);

        .unit-label {
          font-size: 15px;
          display: flex;
          align-items: flex-end;
          height: 75%;
        }
      }
      &--hours {
        border-right: 1px solid rgba(128, 128, 128, 0.637);
      }

      .weekend {
        color: var(--bs-stratos);
        text-shadow: 1px 2px rgba(173, 173, 173, 0.349);
      }
      .night-overtime {
        color: var(--bs-stratos);
        text-shadow: 1px 2px rgba(173, 173, 173, 0.349);
      }
      .public-holiday {
        color: var(--bs-stratos);
        text-shadow: 1px 2px rgba(173, 173, 173, 0.349);
      }
    }

    &__btn-group {
      display: flex;
      justify-content: space-between;

      width: 100%;
      padding-top: 20px;
      padding-bottom: 10px;

      &-view {
        background-color: var(--bs-background-color-new);
        color: var(--bs-stratos);
        border: 1px solid  var(--bs-bondi-blue) !important;
        &:hover {
          background-color: var(--bs-button-new);
          color: white;
        }
      }

      .button__export-pdf{
        background-color: var(--bs-background-color-new);
        color: var(--bs-stratos);
        border: 1px solid  var(--bs-bondi-blue) !important;
      }

      .button__export-pdf:hover {
        background-color: var(--bs-button-new);
        color: white;
      }
    }


    &__create {
      display: flex;
      justify-content: center;
      padding: 15px 0px;
      
      &-new {
        background-color: var(--bs-button-new);
      }

      &-new:hover {
        background-color: var(--bs-regal-blue);
      }
    }
  }

  .export__table {
    display: block;
    background-color: white;

    padding: 15px;
    height: 63%;
    // .MuiDataGrid-row:nth-child(even) {
    //   background-color: aliceblue;
    // }
    .MuiDataGrid-header {
      background-color: var(--bs-regal-blue);
      color: white;
      border-right: 1px solid rgb(32, 30, 30);
    }
    .MuiDataGrid-header:first-child {
      border-left: 1px solid rgb(32, 30, 30);
    }

    .MuiDataGrid-columnHeaders {
      border-top: 1px solid black;
      border-bottom: 1px solid black;
    }

    .MuiDataGrid-columnHeaderTitle {
      font-weight: bold;
    }
    .MuiDataGrid-virtualScroller {
      font-weight: 650;
    }
    .MuiDataGrid-tablecell {
      border-right: 1px solid rgb(32, 30, 30);
      border-bottom: 1px solid rgb(32, 30, 30) !important;
    }
    .MuiDataGrid-tablecell:first-child {
      border-left: 1px solid rgb(32, 30, 30);
    }
    .MuiDataGrid-columnSeparator {
      visibility: hidden;
    }
    .MuiFormControl-fullWidth {
      width: 120px;
    }

    .status--approved {
      color: var(--bs-approved);
    }

    .status--rejected {
      color: var(--bs-rejected);
    }

    .status--pending {
      color:  var(--bs-pending);
    }

    .list-ot__edit {
      cursor: pointer;
      line-height: 20px !important;
    }

    .weekday {
      color: var(--bs-weekday);
    }
    .weekend {
      color: var(--bs-weekend);
    }
    .night-overtime {
      color: var(--bs-night-overtime);
    }
    .public-holiday {
      color: var(--bs-public-holiday);
    }
  }
}



/****** RESPONSIVE ******/
// X-Small devices (portrait phones, 360px and up
@media only screen and (min-width: 360px) and (max-width: 639px) {
  .container {
    .overtime {
      font-size: 12px;
      display: inline;

      &__info {
        flex-basis: 100%;
        margin-bottom: 20px;
        .info__grid {
          display: grid;
          grid-template-columns: 55% 45%;
          grid-column-gap: 3%;

          .employee__name,
          .employee__department {
            font-size: 13px;
            font-weight: 600;

            margin: 15px 0px;
          }

          .date-from,
          .date-to {
            text-align: center;
            font-size: 13px;
            font-weight: 600;
          }
        }
      }
      &__totaltime {
        font-size: 13px;

        &--hours {
          font-size: 20px !important;
        }
      }
    }
    .export__table {
      height: 38%;
    }
  }
}

// Small devices (landscape phones, 640px and up)
@media only screen and (min-width: 640px) and (max-width: 767px) {
  .container {
    .overtime {
      display: inline;
      &__info {
        flex-basis: 100%;
        margin-bottom: 20px;
        .info__grid {
          display: grid;
          grid-template-columns: 45% 45%;
          grid-column-gap: 10%;
        }
      }
      &__totaltime {
        &--hours {
          font-size: 35px !important;
        }
      }
    }
    .export__table {
      height: 50%;
    }
  }
}

// Medium devices (tablets, 768px and up)
@media only screen and (min-width: 768px) and (max-width: 1199px) {
  .container {
    .overtime {
      display: inline;
      &__info {
        flex-basis: 100%;
        margin-bottom: 20px;
        .info__grid {
          display: grid;
          grid-template-columns: 45% 45%;
          grid-column-gap: 10%;
        }
      }
      &__totaltime {
        &--hours {
          font-size: 35px !important;
        }
      }
    }
    .export__table {
      height: 50%;
    }
  }
}

// Large devices I (large desktops, 1200px and up)
@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .container {
    .overtime {
      display: flex;
      &__info {
        flex-basis: 70%;

        .info__grid {
          display: grid;
          grid-template-columns: 35% 35%;
          grid-column-gap: 10%;
        }
      }
      &__totaltime {
        &--hours {
          font-size: 35px !important;
        }
      }
    }
    .export__table {
      height: 50%;
    }
  }
}

// // Large devices II (larger desktops, 1400px and up)
@media only screen and (min-width: 1400px) and (max-width: 1919px) {
  .container {
    .overtime {
      display: flex;
      &__info {
        flex-basis: 70%;

        .info__grid {
          display: grid;
          grid-template-columns: 35% 35%;
          grid-column-gap: 10%;
        }
      }
    }
    .export__table {
      height: 50%;
    }
  }
}
