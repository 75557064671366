$XSmall: 360px;
$Small: 640px;
$Medium: 768px;
$Large: 1200px;
$XLarge: 1400px;

@mixin XSmallDevices {
  @media (min-width: #{$XSmall}) and (max-width: #{$Small - 1px}) {
    @content;
  }
}

@mixin SmallDevices {
  @media (min-width: #{$Small}) and (max-width: #{$Medium - 1px}) {
    @content;
  }
}

@mixin MediumDevices {
  @media (min-width: #{$Medium}) and (max-width: #{$Large - 1px}) {
    @content;
  }
}

@mixin LargeDevices {
  @media (min-width: #{$Large}) and (max-width: #{$XLarge - 1px}) {
    @content;
  }
}

@mixin XLargeDevices {
  @media (min-width: #{$XLarge}) {
    @content;
  }
}

.button {
  text-transform: none !important;

  &.--primary {
    background-color: var(--bs-primary);
    color: white;

    &:hover {
      background-color: var(--bs-primary);
    }
  }

  &.--success {
    background-color: var(--bs-button-new);

    &:hover {
      background-color: var(--bs-regal-blue);
    }
  }

  &.--warning {
    //background-color: var(--bs-warning);
    background-color: var(--bs-background-color-new);
    color: var(--bs-stratos);
    border: 1px solid var(--bs-bondi-blue) !important;
    &:hover {
      //background-color: #dba446;
      background-color: var(--bs-button-new);
      color: white;
    }
  }

  &.--danger {
    background-color: var(--bs-gray-new);
    color: var(--bs-stratos) !important;

    &:hover {
      background-color: var(--bs-button-new);
      color: var(--bs-gray-new) !important;
    }
  }

  &.--info {
    //background-color: var(--bs-info);
    background-color: var(--bs-background-color-new);
    color: var(--bs-stratos);
    border: 1px solid var(--bs-bondi-blue) !important;

    &:hover {
      //background-color: #3898ec;
      background-color: var(--bs-button-new);
      color: white;
    }
  }
}

.color {
  &--danger {
    color: var(--bs-danger) !important;
  }

  &--success {
    color: var(--bs-regal-blue) !important;
  }

  &--info {
    color: var(--bs-stratos) !important;
  }

  &--primary {
    color: var(--bs-primary) !important;
  }
}
.scrollable {
  float: left;
  width: calc(100%);
  height: calc(100%);
  margin-top: 5px;
  background: inherit;
  overflow: auto;
  overflow-x: hidden;

  &::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 10px rgba(0, 0, 0, 0.3);
    border-radius: 10px;
    background-color: transparent;
  }

  &::-webkit-scrollbar {
    width: 8px;
    background-color: transparent;

    @include LargeDevices {
      // height: calc(100vh);
    }

    @include MediumDevices {
      width: 2px;
    }

    @include SmallDevices {
      width: 2px;
    }

    @include XSmallDevices {
      width: 2px;
    }
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 10px;
    -webkit-box-shadow: inset 0 0 10px rgba(0, 0, 0, 0.3);
    background-color: var(--bs-gray-400);
  }
}

.calendar-wrap {
  height: calc(100vh - 56px);
  max-height: 100vh;
  overflow: overlay;
  display: flex;
  font-weight: 500;
  background-color: var(--bs-background-color-new);

  @include LargeDevices {
    // height: calc(100vh);
  }

  @include MediumDevices {
    width: calc(100vw - 200px) !important;
  }

  @include SmallDevices {
    width: calc(100vw - 70px) !important;
  }

  @include XSmallDevices {
    width: calc(100vw - 70px) !important;
  }

  .calendar {
    // width: 100%;

    @include LargeDevices {
      // height: calc(100vh);
    }

    @include MediumDevices {
      padding: 10px;
    }

    @include SmallDevices {
      padding: 6px;
    }

    @include XSmallDevices {
      padding: 6px;
    }

    .calendar__header {
      height: 70px;
      width: 100%;
      background-color: var(--bs-regal-blue);
      color: var(--bs-blue-new);
      display: flex;
      justify-content: center;

      @include LargeDevices {
        height: 70px;
      }

      @include MediumDevices {
        height: auto;
        flex-direction: column;
        align-items: center;
      }

      @include SmallDevices {
        height: auto;
        flex-direction: column;
        align-items: center;
      }

      @include XSmallDevices {
        height: auto;
        flex-direction: column;
        align-items: center;
      }

      .user__wrap {
        height: 100%;
        width: 100%;
        color: #a6b0cf;

        .user__container {
          width: 350px;
          height: 100%;
          margin-left: 50px;
          display: flex;
          align-items: center;
          justify-content: flex-start;
          z-index: 1;

          @include LargeDevices {
            width: 290px;
          }

          @include MediumDevices {
            margin: auto;
            height: 50px;
            width: 50%;
            justify-content: center;
          }

          @include SmallDevices {
            margin: auto;
            height: 45px;
            width: 70%;
            justify-content: center;
          }

          @include XSmallDevices {
            margin: auto;
            height: 45px;
            width: 75%;
            justify-content: center;
          }

          .user__avatar {
            width: 40px;
            height: 40px;

            @include LargeDevices {
              width: 36px;
              height: 36px;
            }

            @include MediumDevices {
              width: 40px;
              height: 40px;
            }

            @include SmallDevices {
              width: 36px;
              height: 36px;
            }

            @include XSmallDevices {
              width: 36px;
              height: 36px;
            }
          }

          .user__text {
            font-size: 20px;
            font-weight: 700;
            white-space: nowrap;
            overflow: hidden !important;
            text-overflow: ellipsis;
            padding: 0px 16px;

            @include LargeDevices {
              font-size: 18px;
            }

            @include MediumDevices {
              font-size: 16px;
            }

            @include SmallDevices {
              font-size: 16px;
            }

            @include XSmallDevices {
              font-size: 16px;
            }
          }

          .user__button {
            width: 46px;
            height: 46px;

            @include LargeDevices {
              width: 40px;
              height: 40px;
            }

            @include MediumDevices {
              width: 36px;
              height: 36px;
            }

            @include SmallDevices {
              width: 34px;
              height: 34px;
            }

            @include XSmallDevices {
              width: 32px;
              height: 32px;
            }
          }
        }

        .menu_user__wrap {
          display: flex;
          flex-wrap: wrap;

          :not(style) {
            margin: 1px;
            height: auto;
            position: absolute;
          }
        }

        .menu_user {
          width: 300px;
          z-index: 1;

          @include LargeDevices {
            width: 280px;
          }

          @include MediumDevices {
            width: 280px;
            margin: auto;
          }

          @include SmallDevices {
            width: 60%;
            margin: auto;
          }

          @include XSmallDevices {
            width: 60%;
            margin: auto;
          }

          .menu_user__search {
            height: 40px;
            width: calc(100% - 2px);
            margin: 1px;
            display: flex;
            align-items: center;
            padding: 1px;
            background-color: white;
            // border: 1px solid var(--bs-gray-700);

            font-size: 18px;

            @include LargeDevices {
              font-size: 18px;
              padding: 8px;
            }

            @include MediumDevices {
              font-size: 16px;
              padding: 5px;
            }

            @include SmallDevices {
              font-size: 16px;
              padding: 5px;
            }

            @include XSmallDevices {
              font-size: 16px;
              padding: 5px;
            }

            .search__icon {
              width: 40px;

              @include LargeDevices {
                width: 36px;
              }

              @include MediumDevices {
                width: 30px;
              }

              @include SmallDevices {
                width: 26px;
              }

              @include XSmallDevices {
                width: 20px;
              }
            }

            .search__input {
              width: calc(100% - 40px);
              // padding-left: 14px;

              @include LargeDevices {
                width: calc(100% - 36px);
              }

              @include MediumDevices {
                width: calc(100% - 30px);
              }

              @include SmallDevices {
                width: calc(100% - 26px);
              }

              @include XSmallDevices {
                width: calc(100% - 20px);
              }
            }
          }

          .menu_user__container {
            background-color: white;
            max-height: 400px;
            border-radius: 10px;
            overflow: auto;
            overflow-x: hidden;

            .menu_user__item {
              width: 100%;

              .item__avatar {
                width: 36px;
                height: 36px;

                @include LargeDevices {
                  width: 32px;
                  height: 32px;
                }

                @include MediumDevices {
                  width: 32px;
                  height: 32px;
                  max-height: 600px;
                }

                @include SmallDevices {
                  width: 32px;
                  height: 32px;
                  max-height: 600px;
                }

                @include XSmallDevices {
                  width: 32px;
                  height: 32px;
                  max-height: 600px;
                }
              }

              .item__text {
                font-size: 16px;
                font-weight: 600;
                padding: 5px;
                white-space: nowrap;
                overflow: hidden !important;
                text-overflow: ellipsis;

                @include LargeDevices {
                }

                @include MediumDevices {
                  font-size: 16px;
                }

                @include SmallDevices {
                  font-size: 16px;
                }

                @include XSmallDevices {
                  font-size: 16px;
                }
              }
            }
          }
        }

        .button {
          &:hover {
            background-color: var(--bs-gray-700);
          }

          .button__icon {
            color: #a6b0cf;
            font-size: 30px;
          }
        }
      }

      .none__wrap {
        display: flex;
        height: 100%;
        width: 100%;

        align-items: center;
        justify-content: flex-end;
        padding-right: 30px;
      }
    }

    .calendar__body {
      width: calc(100%);
      display: flex;
      border: 1px solid var(--bs-gray-500);

      .week__wrap {
        width: 50px;
        margin-top: 30px;
        display: flex;
        flex-wrap: wrap;

        @include LargeDevices {
          // height: calc(100vh);
        }

        @include MediumDevices {
          width: 50px;
        }

        @include SmallDevices {
          width: 30px;
        }

        @include XSmallDevices {
          display: none;
        }

        .week {
          width: 100%;
          height: 145px;
          padding: 10px;
          border: 1px solid var(--bs-gray-500);
          border-width: 1px 1px 0px 0px;
          display: flex;
          align-items: center;
          justify-content: center;

          @include LargeDevices {
            // height: calc(100vh);
          }

          @include MediumDevices {
            height: 100px;
            padding: 5px;
          }

          @include SmallDevices {
            height: 80px;
          }

          @include XSmallDevices {
          }

          .week__text {
            line-height: 1;
            font-weight: 700;
            color: var(--bs-stratos);
          }
        }
      }

      .date__wrap {
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        align-content: flex-start;

        .date-name__wrap {
          height: 30px;
          width: 100%;
          margin-left: -1px;
          display: flex;

          .date-name {
            width: calc(100% / 7);
            height: 100%;
            border: 1px solid var(--bs-gray-500);
            border-width: 0px 0px 0px 1px;
            display: flex;
            align-items: center;
            justify-content: center;
            margin: auto;

            .date-name__text {
              font-weight: 700;
              color: var(--bs-regal-blue);
              display: flex;
              justify-content: center;
              width: 100%;

              @include LargeDevices {
                // height: calc(100vh);
              }

              @include MediumDevices {
                font-size: 13px;
              }

              @include SmallDevices {
                font-size: 13px;
              }

              @include XSmallDevices {
                font-size: 13px;
              }
            }
          }
        }

        .date__container {
          width: calc((100% / 7));
          height: 145px;
          border: 1px solid var(--bs-gray-500);
          border-width: 1px 1px 0px 0px;
          display: flex;

          @include LargeDevices {
            // height: calc(100vh);
          }

          @include MediumDevices {
            height: 100px;
            padding: 2px;
          }

          @include SmallDevices {
            height: 60px;
            padding: 1px;
          }

          @include XSmallDevices {
            height: 60px;
            padding: 1px;
          }

          &:hover {
            background-color: var(--bs-gray-300);
            cursor: pointer;
          }

          &--fade {
            background-color: #e3e3e3 !important;
          }

          &--active {
            background-color: var(--bs-bottcelli);
          }

          &--weekend {
            background-color: var(--bs-gray-new);
          }

          &--warning {
            background-color: #f71500;
            &:hover {
              background-color: #f25142e3 !important;
            }
          }

          .date {
            padding: 3px;
            width: 100%;
            height: 100%;
            overflow: hidden;

            @include LargeDevices {
              // height: calc(100vh);
            }

            @include MediumDevices {
              padding: 0px;
            }

            @include SmallDevices {
              padding: 0px;
            }

            @include XSmallDevices {
              padding: 0px;
            }

            .date__text {
              width: 100%;
              display: flex;
              flex-wrap: wrap-reverse;
              cursor: default;
              font-size: 14px;

              @include LargeDevices {
                font-size: 14px;
              }

              @include MediumDevices {
                font-size: 14px;
              }

              @include SmallDevices {
                font-size: 14px;
              }

              @include XSmallDevices {
                font-size: 14px;
              }

              .date__time {
                width: 70%;
                display: flex;
                flex-wrap: wrap;
                color: var(--bs-gray-500);
                font-size: 14px;

                @include LargeDevices {
                  width: 100%;
                }

                @include MediumDevices {
                  height: 0px;
                }

                @include SmallDevices {
                  height: 0px;
                }

                @include XSmallDevices {
                  height: 0px;
                }

                &--success {
                  color: #5bb318;
                }

                &--danger {
                  color: #ff8b8b;
                }

                .time__in {
                  width: 40%;

                  @include LargeDevices {
                    // width: 100%;
                  }

                  @include MediumDevices {
                    width: 100%;
                    height: 0px;
                    visibility: hidden;
                  }

                  @include SmallDevices {
                    width: 100%;
                    height: 0px;
                    visibility: hidden;
                  }

                  @include XSmallDevices {
                    width: 100%;
                    height: 0px;
                    visibility: hidden;
                  }
                }

                .time__out {
                  width: 45%;

                  @include LargeDevices {
                    // width: 100%;
                  }

                  @include MediumDevices {
                    width: 100%;
                    height: 0px;
                    visibility: hidden;
                  }

                  @include SmallDevices {
                    width: 100%;
                    height: 0px;
                    visibility: hidden;
                  }

                  @include XSmallDevices {
                    width: 100%;
                    height: 0px;
                    visibility: hidden;
                  }
                }

                .edit__button {
                  width: 15%;
                  color: var(--bs-regal-blue);
                  @include LargeDevices {
                    // width: 100%;
                  }

                  @include MediumDevices {
                    width: 100%;
                    height: 0px;
                    visibility: hidden;
                  }

                  @include SmallDevices {
                    width: 100%;
                    height: 0px;
                    visibility: hidden;
                  }

                  @include XSmallDevices {
                    width: 100%;
                    height: 0px;
                    visibility: hidden;
                  }
                }
              }

              .date__day {
                user-select: none;
                font-weight: 600;
                width: 30%;
                display: flex;
                justify-content: flex-end;
                align-items: center;
                color: var(--bs-regal-blue);

                @include LargeDevices {
                  width: 100%;
                }

                @include MediumDevices {
                  width: 100%;
                }

                @include SmallDevices {
                  width: 100%;
                }

                @include XSmallDevices {
                  width: 100%;
                }

                .note__button {
                  height: 18px;
                  width: 18px;
                  margin-right: 5px;
                  color: var(--bs-regal-blue);

                  @include LargeDevices {
                    // width: 100%;
                  }

                  @include MediumDevices {
                    height: 16px;
                    width: 16px;
                  }

                  @include SmallDevices {
                    height: 16px;
                    width: 16px;
                  }

                  @include XSmallDevices {
                    height: 16px;
                    width: 16px;
                  }

                  .button__icon {
                    font-size: 16px;
                  }
                }
              }
            }

            .timesheet {
              width: 100%;
              height: 100px;

              @include LargeDevices {
                height: 85px;
              }

              @include MediumDevices {
                height: calc(100% - 20px);
              }

              @include SmallDevices {
                height: calc(100% - 20px);
              }

              @include XSmallDevices {
                height: calc(100% - 20px);
              }

              .timesheet-item {
                width: calc(100% - 4px);
                line-height: 1.75;
                font-size: 13px;
                border-radius: 2px;
                color: white;
                display: flex;
                align-items: center;
                user-select: none;

                @include LargeDevices {
                  // height: calc(100vh);
                }

                @include MediumDevices {
                  line-height: 1.05;
                }

                @include SmallDevices {
                  line-height: 1.05;
                }

                @include XSmallDevices {
                  line-height: 1.05;
                }

                :hover {
                  cursor: pointer;
                }

                &--info {
                  background-color: var(--bs-morning-glory);
                  color: black;

                  :hover {
                    background-color: var(--bs-bondi-blue);
                    color: white;
                  }
                }

                &--success {
                  background-color: var(--bs-success);
                }

                &--danger {
                  background-color: var(--bs-danger);
                }

                &--leave-task {
                  background-color: var(--bs-gray-600);
                }
                &--ot-task {
                  background-color: var(--bs-pending);

                  :hover {
                    background-color: #fdcf02;
                    color: white;
                  }
                }

                &--warning {
                  background-color: var(--bs-warning);
                }

                &--secondary {
                  background-color: var(--bs-secondary);
                }

                .timesheet-item__text {
                  width: 100%;
                  display: flex;
                  padding: 2px;

                  .text__project {
                    width: 75%;
                    white-space: nowrap;
                    overflow: hidden !important;
                    text-overflow: ellipsis;

                    @include LargeDevices {
                      // height: calc(100vh);
                    }

                    @include MediumDevices {
                      display: none;
                    }

                    @include SmallDevices {
                      display: none;
                    }

                    @include XSmallDevices {
                      display: none;
                    }
                  }

                  .text__time {
                    width: 25%;
                    display: flex;
                    justify-content: flex-end;
                    padding-right: 2px;

                    @include LargeDevices {
                      // height: calc(100vh);
                    }

                    @include MediumDevices {
                      width: 100%;
                      justify-content: center;
                      font-size: 10px;
                    }

                    @include SmallDevices {
                      width: 100%;
                      justify-content: center;
                      font-size: 10px;
                    }

                    @include XSmallDevices {
                      width: 100%;
                      justify-content: center;
                      font-size: 10px;
                    }
                  }
                }

                .timesheet-item__button {
                  height: 16px;
                  width: 16px;
                  margin: 0 2px;
                  color: white;

                  @include LargeDevices {
                    // height: calc(100vh);
                  }

                  @include MediumDevices {
                    margin: 0px;
                  }

                  @include SmallDevices {
                    margin: 0px;
                  }

                  @include XSmallDevices {
                    margin: 0px;
                  }

                  .button__icon {
                    font-size: 14px;
                    cursor: pointer;

                    @include LargeDevices {
                      // height: calc(100vh);
                    }

                    @include MediumDevices {
                      font-size: 14px;
                    }

                    @include SmallDevices {
                      font-size: 13px;
                    }

                    @include XSmallDevices {
                      font-size: 12px;
                    }

                    &:hover {
                      color: var(--bs-gray-400);
                    }
                  }
                }
              }
            }

            .summary {
              width: 100%;
              font-size: 14px;
              display: flex;

              @include LargeDevices {
                font-size: 12px;
              }

              @include MediumDevices {
                display: none;
              }

              @include SmallDevices {
                display: none;
              }

              @include XSmallDevices {
                display: none;
              }

              .summary__ot {
                width: 50%;
                font-weight: 700;

                &.hidden {
                  visibility: hidden;
                }
              }

              .summary__total {
                width: 50%;
                color: var(--bs-info);
                display: flex;
                justify-content: flex-end;

                &.hidden {
                  visibility: hidden;
                }
              }
            }
          }
        }
      }
    }
  }
}

.timesheet-component {
  height: calc(100vh - 55px);
  max-height: 100vh;
  overflow: overlay;
  padding: 16px;
  display: block;

  @include LargeDevices {
    // height: calc(100vh);
  }

  @include MediumDevices {
    width: calc(100vw - 200px) !important;
  }

  @include SmallDevices {
    width: calc(100vw - 70px) !important;
  }

  @include XSmallDevices {
    width: calc(100vw - 70px) !important;
  }

  .timesheet-component__wrap {
    @include LargeDevices {
      // height: calc(100vh);
    }

    @include MediumDevices {
      padding: 10px;
    }

    @include SmallDevices {
      padding: 6px;
    }

    @include XSmallDevices {
      padding: 6px;
    }
  }

  .time-in__wrap {
    width: 33%;

    @include LargeDevices {
      // height: calc(100vh);
    }

    @include MediumDevices {
      width: 50%;
    }

    @include SmallDevices {
      width: 50%;
    }

    @include XSmallDevices {
      width: 50%;
    }
  }

  .time-out__wrap {
    width: 33%;

    @include LargeDevices {
      // height: calc(100vh);
    }

    @include MediumDevices {
      width: 50%;
    }

    @include SmallDevices {
      width: 50%;
    }

    @include XSmallDevices {
      width: 50%;
    }
  }

  .time-date__wrap {
    width: 33%;

    @include LargeDevices {
      // height: calc(100vh);
    }

    @include MediumDevices {
      width: 100%;
    }

    @include SmallDevices {
      width: 100%;
    }

    @include XSmallDevices {
      width: 100%;
    }
  }

  .project-detail-component__wrap {
    width: 33%;
    height: 650px;

    @include LargeDevices {
      // height: calc(100vh);
    }

    @include MediumDevices {
      width: 100%;
      order: 2;
    }

    @include SmallDevices {
      width: 100%;
      order: 2;
    }

    @include XSmallDevices {
      width: 100%;
      order: 2;
    }
  }

  .project-list__wrap {
    width: 33%;
    height: 650px;

    @include LargeDevices {
      // height: calc(100vh);
    }

    @include MediumDevices {
      height: 350px;
      width: 100%;
      order: 1;
    }

    @include SmallDevices {
      height: 350px;
      width: 100%;
      order: 1;
    }

    @include XSmallDevices {
      height: 350px;
      width: 100%;
      order: 1;
    }
  }

  .timesheet__time-tag {
    line-height: 0.85;
    font-size: 108px;
    font-weight: 400;
    color: var(--bs-gray-400);

    @include LargeDevices {
      font-size: 80px;
    }

    @include MediumDevices {
      font-size: 30px;
      line-height: 1;
      width: 50%;
    }

    @include SmallDevices {
      font-size: 30px;
      line-height: 1;
    }

    @include XSmallDevices {
      font-size: 30px;
      line-height: 1;
    }
  }

  .timesheet__date-tag {
    line-height: 0.85;
    font-weight: 400;
    color: var(--bs-stratos);
    display: flex;
    flex-wrap: wrap;

    @include LargeDevices {
      font-size: 25px;
      width: 100%;
    }

    @include MediumDevices {
      font-size: 20px;
      width: 100%;
    }

    @include SmallDevices {
      font-size: 20px;
      width: 100%;
    }

    @include XSmallDevices {
      font-size: 20px;
      width: 100%;
    }

    .date-tag__day {
      font-size: 35px;
      margin-bottom: 15px;
      width: 100%;

      @include LargeDevices {
        font-size: 25px;
      }

      @include MediumDevices {
        margin-bottom: 5px;
        font-size: 18px;
        width: 100%;
      }

      @include SmallDevices {
        margin-bottom: 5px;
        font-size: 18px;
        width: 100%;
      }

      @include XSmallDevices {
        margin-bottom: 5px;
        font-size: 18px;
        width: 100%;
      }
    }

    .date-tag__date {
      font-size: 50px;
      width: 100%;

      @include LargeDevices {
        font-size: 30px;
      }

      @include MediumDevices {
        font-size: 20px;
      }

      @include SmallDevices {
        font-size: 20px;
      }

      @include XSmallDevices {
        font-size: 20px;
      }
    }
  }
}

.timesheet-calendar__container {
  width: 100%;
  height: 100%;
  display: flex;
  flex-wrap: wrap;

  .timesheet__note-noti {
    width: 20%;
    height: 100%;
    padding: 16px;
    display: flex;
    justify-content: center;

    .note__title {
      font-size: 16px;
      color: var(--bs-stratos);
      font-weight: 600;
    }

    .note__button {
      margin-top: 4px;
      margin-left: 5px;
      height: 18px;
      width: 18px;
      margin-right: 5px;
      color: var(--bs-stratos);

      .button__icon {
        font-size: 16px;
      }
    }
  }

  .timesheet__calendar {
    width: 80%;
    height: 100%;
  }
}

.project-detail-component {
  width: 100%;
  height: 100%;
  display: flex;
  flex-wrap: wrap;
  align-content: space-between;
  overflow-y: auto;

  .form-component__group {
    width: 75%;

    &:not(:first-child) {
      margin-top: 6px;
    }

    .group__title {
      width: 100%;
      font-size: 16px;
      text-transform: uppercase;
      color: black;
      font-weight: 600;
    }

    .group__input {
      margin-top: 10px;
      width: 100%;

      &--project {
        width: 60%;

        @include LargeDevices {
          width: 60%;
        }

        @include MediumDevices {
          width: 100%;
        }

        @include SmallDevices {
          width: 100%;
        }

        @include XSmallDevices {
          width: 100%;
        }
      }
    }
  }

  .form-component__icon--lock {
    width: 25%;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding-right: 1%;
  }

  .task-container {
    width: 100%;
    height: 75%;
    margin-top: 10px;

    .task-container__header {
      width: 100%;
      display: flex;

      &--mobile {
        font-size: 16px;
        text-transform: uppercase;
        color: black;
        font-weight: 600;
        display: none;

        @include LargeDevices {
          display: none;
        }

        @include MediumDevices {
          display: flex;
        }

        @include SmallDevices {
          display: flex;
        }

        @include XSmallDevices {
          display: flex;
        }
      }

      .header__col {
        font-size: 16px;
        text-transform: uppercase;
        color: black;
        font-weight: 600;

        @include LargeDevices {
          // font-size: 16px;
        }

        @include MediumDevices {
          font-size: 14px;
        }

        @include SmallDevices {
          font-size: 14px;
        }

        @include XSmallDevices {
          font-size: 14px;
        }

        &--category,
        &--object {
          width: 20%;

          @include LargeDevices {
            // font-size: 16px;
          }

          @include MediumDevices {
            display: none;
          }

          @include SmallDevices {
            display: none;
          }

          @include XSmallDevices {
            display: none;
          }
        }

        &--description {
          width: 35%;

          @include LargeDevices {
            width: 30%;
          }

          @include MediumDevices {
            display: none;
          }

          @include SmallDevices {
            display: none;
          }

          @include XSmallDevices {
            display: none;
          }
        }

        &--hour {
          width: 10%;

          @include LargeDevices {
            // font-size: 16px;
          }

          @include MediumDevices {
            display: none;
          }

          @include SmallDevices {
            display: none;
          }

          @include XSmallDevices {
            display: none;
          }
        }

        &:not(:first-child) {
          margin-left: 5px;
        }
      }
    }

    .task-container__body {
      width: 100%;

      .body__row {
        width: 100%;
        display: flex;
        align-items: center;
        flex-wrap: wrap;

        .row__input {
          margin-top: 5px;
          margin-bottom: 5px;
          width: 100%;

          &:not(:last-child) {
            margin-right: 5px;
          }

          &--category,
          &--object {
            width: 20%;

            @include LargeDevices {
              // font-size: 16px;
            }

            @include MediumDevices {
              width: 45%;
            }

            @include SmallDevices {
              width: 45%;
            }

            @include XSmallDevices {
              width: 45%;
            }
          }

          &--description {
            width: 35%;

            @include LargeDevices {
              width: 30%;
            }

            @include MediumDevices {
              width: 50%;
            }

            @include SmallDevices {
              width: 70%;
            }

            @include XSmallDevices {
              width: 70%;
            }
          }

          &--hour {
            width: 15%;

            @include LargeDevices {
              // font-size: 16px;
            }

            @include MediumDevices {
              width: 20%;
            }

            @include SmallDevices {
              width: 20%;
            }

            @include XSmallDevices {
              width: 20%;
            }
          }
        }

        .row__button {
          width: calc(10% - 20px);
          font-size: 10px;
          height: 42px;
          display: flex;
          align-items: center;
          span {
            margin: 0 !important;
          }

          @include LargeDevices {
            width: calc(10% - 20px);
            font-size: 10px;
          }

          @include MediumDevices {
            width: 20%;
            font-size: 10px;
          }

          @include SmallDevices {
            width: 30%;
            font-size: 10px;
          }

          @include XSmallDevices {
            width: 30%;
            font-size: 10px;
          }

          &:not(:first-child) {
            // margin-left: 5px;

            @include LargeDevices {
              // margin-left: 5px;
            }

            @include MediumDevices {
              // margin-left: 5px;
            }

            @include SmallDevices {
              margin-left: calc(35%);
            }

            @include XSmallDevices {
              margin-left: calc(35%);
            }
          }
        }
      }
    }
  }

  .button-container {
    width: 100%;
    display: flex;
    margin-top: 10px;

    &--space-between {
      justify-content: space-between;
    }

    &--center {
      justify-content: center;
    }
  }
}

.project-list {
  width: 100%;
  height: 100%;
  display: flex;
  flex-wrap: wrap;
  overflow-y: auto;
  align-content: space-between;

  .project-list__header {
    display: flex;
    flex: 1 1;
    justify-content: space-between;
    height: 22px;

    .header__title {
      font-size: 16px;
      text-transform: uppercase;
      color: black;
      font-weight: 600;
    }
  }

  .project-list__body {
    width: 100%;
    height: calc(100% - 75px);

    .project__row {
      width: 100%;
      padding: 10px;
      border: 1px solid #c4c4c4;
      border-radius: 3px;
      display: flex;
      cursor: pointer;

      &:hover {
        border: 1px solid #000;
      }

      &:not(:first-child) {
        margin-top: 10px;
      }

      .project__name {
        width: 80%;
        white-space: nowrap;
        overflow: hidden !important;
        text-overflow: ellipsis;
      }

      .project__time {
        width: 20%;
        display: flex;
        justify-content: flex-end;
        font-size: 16px;
        font-weight: 600;
        color: var(--bs-stratos);
      }
    }
  }

  .project-list__button-container {
    width: 100%;
    height: 40px;
    display: flex;
    margin-top: 10px;
  }
}

.note-popup {
  width: 600px;

  .popup__title {
    font-size: 16px;
    text-transform: uppercase;
    color: var(--bs-gray-600);
    font-weight: 600;
    display: flex;
    justify-content: center;
  }

  .form-group {
    &:not(:last-child) {
      margin-bottom: 10px;
    }

    .form__title {
      font-size: 14px;
      color: var(--bs-gray-500);
      font-weight: 600;
      width: 100%;

      &:not(:last-child) {
        margin-bottom: 10px;
      }
    }

    .form__input {
      display: flex;
      flex-wrap: wrap;

      .input {
        width: 100%;

        &--20 {
          width: 20%;

          &:not(:first-child) {
            margin-left: 10px;
          }
        }
      }
    }
  }

  .button-container {
    padding: 0px 24px 20px;
  }
}

.width {
  &--60 {
    width: 60% !important;
  }

  &--50 {
    width: 50% !important;
  }

  &--40 {
    width: 40% !important;
  }

  &--30 {
    width: 30% !important;
  }

  &--35 {
    width: 35% !important;
  }

  &--20 {
    width: 20% !important;
  }

  &--15 {
    width: 15% !important;
  }

  &--10 {
    width: 10% !important;
  }

  @include LargeDevices {
  }

  @include MediumDevices {
  }

  @include SmallDevices {
  }

  @include XSmallDevices {
  }
}
