.export {
  font-family: "Times New Roman", Roboto;
  display: none;
  padding: 50px 85px;

  &__info {
    display: flex;
    flex-flow: wrap;

    &--logo {
      flex-grow: 1.5;
    }

    &--address {
      flex-grow: 4;
      font-size: 12px;

      // .line_normal {
      //   line-height: 5;
      // }
    }

    &--box {
      width: 100%;
      height: 15px;
      margin: 10px 0px;
      background-color: #333399;
    }
  }

  &__table {
    margin: 15px 0px;
    #list-leave {
      font-family: Arial, Helvetica, sans-serif;
      border-collapse: collapse;
      width: 100%;
    }

    #list-leave td,
    #list-leave th {
      border: 1px solid black;
      padding: 5px;
      font-size: 12px;
      text-align: center;
      font-family: "Times New Roman", Roboto;
    }

    #list-leave tr:hover {
      background-color: rgb(140, 182, 223);
    }

    #list-leave th {
      padding-top: 15px;
      padding-bottom: 15px;
      background-color: #bdd7ee;
      text-align: center;
      font-size: 15px;
    }
  }

  &__content {
    display: block;

    &--title {
      display: block;

      font-size: 20px;
      text-align: center;
    }

    &--year {
      display: block;

      font-size: 20px;
      text-align: center;
      margin: 10px 0px;
    }
    &--name {
      display: block;

      font-size: 15px;
      margin: 10px 0px;
    }
    &--dept {
      display: block;

      font-size: 15px;
      margin: 10px 0px;
    }
  }

  &__signature {
    display: flex;
    margin: 20px 0px;

    &--employee {
      flex-grow: 1;
      text-align: center;
    }

    &--hr {
      flex-grow: 1;
      text-align: center;
    }

    &--leader {
      flex-grow: 1;
      text-align: center;
    }
  }
}

@media print {
  .export {
    display: block;
  }
}
