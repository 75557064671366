$XSmall: 360px;
$Small: 640px;
$Medium: 768px;
$Large: 1200px;
$XLarge: 1400px;

@mixin XSmallDevices {
  @media (min-width: #{$XSmall}) and (max-width: #{$Small - 1px}) {
    @content;
  }
}

@mixin SmallDevices {
  @media (min-width: #{$Small}) and (max-width: #{$Medium - 1px}) {
    @content;
  }
}

@mixin MediumDevices {
  @media (min-width: #{$Medium}) and (max-width: #{$Large - 1px}) {
    @content;
  }
}

@mixin LargeDevices {
  @media (min-width: #{$Large}) and (max-width: #{$XLarge - 1px}) {
    @content;
  }
}

@mixin XLargeDevices {
  @media (min-width: #{$XLarge}) {
    @content;
  }
}

.MuiOutlinedInput-input {
  padding: 0px 0px !important;
}

.button {
  width: 100px;
  text-transform: none !important;

  &.--primary {
    background-color: var(--bs-primary);
    color: white;

    &:hover {
      background-color: var(--bs-primary);
    }
  }

  &.--success {
    background-color: var(--bs-success);

    &:hover {
      background-color: #2ea77a;
    }
  }

  &.--warning {
    background-color: var(--bs-warning);

    &:hover {
      background-color: #dba446;
    }
  }

  &.--danger {
    background-color: var(--bs-danger);

    &:hover {
      background-color: #db5858;
    }
  }

  &.--info {
    background-color: var(--bs-info);

    &:hover {
      background-color: #3898ec;
    }
  }
}

.calendar-wrap {
  height: calc(100vh - 55px);
  max-height: 100vh;
  overflow: overlay;
  display: flex;
  background-color: transparent;

  @include LargeDevices {
    // height: calc(100vh);
  }

  @include MediumDevices {
    width: calc(100vw - 200px) !important;
  }

  @include SmallDevices {
    width: calc(100vw - 70px) !important;
  }

  @include XSmallDevices {
    width: calc(100vw - 70px) !important;
  }

  .calendar {
    // width: 100%;

    @include LargeDevices {
      // height: calc(100vh);
    }

    @include MediumDevices {
      padding: 10px;
    }

    @include SmallDevices {
      padding: 6px;
    }

    @include XSmallDevices {
      padding: 6px;
    }

    .calendar__header {
      height: 70px;
      width: 100%;
      background-color: var(--bs-regal-blue);
      color: white;
      display: flex;
      justify-content: center;

      @include LargeDevices {
        height: 70px;
      }

      @include MediumDevices {
        height: auto;
        flex-direction: column;
        align-items: center;
      }

      @include SmallDevices {
        height: auto;
        flex-direction: column;
        align-items: center;
      }

      @include XSmallDevices {
        height: auto;
        flex-direction: column;
        align-items: center;
      }

      .user__wrap {
        height: 100%;
        width: 100%;
        color: white;

        .user__container {
          width: 350px;
          height: 100%;
          margin-left: 50px;
          display: flex;
          align-items: center;
          justify-content: flex-start;
          z-index: 1;

          @include LargeDevices {
            width: 290px;
          }

          @include MediumDevices {
            margin: auto;
            height: 50px;
            width: 50%;
            justify-content: center;
          }

          @include SmallDevices {
            margin: auto;
            height: 45px;
            width: 70%;
            justify-content: center;
          }

          @include XSmallDevices {
            margin: auto;
            height: 45px;
            width: 75%;
            justify-content: center;
          }

          .user__avatar {
            width: 40px;
            height: 40px;

            @include LargeDevices {
              width: 36px;
              height: 36px;
            }

            @include MediumDevices {
              width: 40px;
              height: 40px;
            }

            @include SmallDevices {
              width: 36px;
              height: 36px;
            }

            @include XSmallDevices {
              width: 36px;
              height: 36px;
            }
          }

          .user__text {
            font-size: 20px;
            font-weight: 700;
            white-space: nowrap;
            overflow: hidden !important;
            text-overflow: ellipsis;
            padding: 0px 16px;

            @include LargeDevices {
              font-size: 18px;
            }

            @include MediumDevices {
              font-size: 16px;
            }

            @include SmallDevices {
              font-size: 16px;
            }

            @include XSmallDevices {
              font-size: 16px;
            }
          }

          .user__button {
            width: 46px;
            height: 46px;

            @include LargeDevices {
              width: 40px;
              height: 40px;
            }

            @include MediumDevices {
              width: 36px;
              height: 36px;
            }

            @include SmallDevices {
              width: 34px;
              height: 34px;
            }

            @include XSmallDevices {
              width: 32px;
              height: 32px;
            }
          }
        }

        .menu_user__wrap {
          display: flex;
          flex-wrap: wrap;

          :not(style) {
            margin: 1px;
            height: auto;
            position: absolute;
          }
        }

        .menu_user {
          width: 300px;
          z-index: 1;

          @include LargeDevices {
            width: 280px;
          }

          @include MediumDevices {
            width: 280px;
            margin: auto;
          }

          @include SmallDevices {
            width: 60%;
            margin: auto;
          }

          @include XSmallDevices {
            width: 60%;
            margin: auto;
          }

          .menu_user__search {
            height: 40px;
            width: calc(100% - 2px);
            margin: 1px;
            display: flex;
            align-items: center;
            padding: 1px;
            background-color: white;
            // border: 1px solid var(--bs-gray-700);

            font-size: 18px;

            @include LargeDevices {
              font-size: 18px;
              padding: 8px;
            }

            @include MediumDevices {
              font-size: 16px;
              padding: 5px;
            }

            @include SmallDevices {
              font-size: 16px;
              padding: 5px;
            }

            @include XSmallDevices {
              font-size: 16px;
              padding: 5px;
            }

            .search__icon {
              width: 40px;

              @include LargeDevices {
                width: 36px;
              }

              @include MediumDevices {
                width: 30px;
              }

              @include SmallDevices {
                width: 26px;
              }

              @include XSmallDevices {
                width: 20px;
              }
            }

            .search__input {
              width: calc(100% - 40px);
              // padding-left: 14px;

              @include LargeDevices {
                width: calc(100% - 36px);
              }

              @include MediumDevices {
                width: calc(100% - 30px);
              }

              @include SmallDevices {
                width: calc(100% - 26px);
              }

              @include XSmallDevices {
                width: calc(100% - 20px);
              }
            }
          }

          .menu_user__container {
            background-color: white;
            max-height: 400px;
            border-radius: 10px;
            overflow: auto;
            overflow-x: hidden;

            .menu_user__item {
              width: 100%;

              .item__avatar {
                width: 36px;
                height: 36px;

                @include LargeDevices {
                  width: 32px;
                  height: 32px;
                }

                @include MediumDevices {
                  width: 32px;
                  height: 32px;
                  max-height: 600px;
                }

                @include SmallDevices {
                  width: 32px;
                  height: 32px;
                  max-height: 600px;
                }

                @include XSmallDevices {
                  width: 32px;
                  height: 32px;
                  max-height: 600px;
                }
              }

              .item__text {
                font-size: 16px;
                font-weight: 600;
                padding: 5px;
                white-space: nowrap;
                overflow: hidden !important;
                text-overflow: ellipsis;

                @include LargeDevices {
                }

                @include MediumDevices {
                  font-size: 16px;
                }

                @include SmallDevices {
                  font-size: 16px;
                }

                @include XSmallDevices {
                  font-size: 16px;
                }
              }
            }
          }
        }

        .button {
          &:hover {
            background-color: var(--bs-gray-700);
          }

          .button__icon {
            color: white;
            font-size: 30px;
          }
        }
      }

      .none__wrap {
        height: 100%;
        width: 100%;
      }
    }

    .calendar__body {
      width: calc(100%);
      display: flex;
      border: 1px solid var(--bs-gray-500);

      .week__wrap {
        width: 50px;
        margin-top: 30px;
        display: flex;
        flex-wrap: wrap;

        @include LargeDevices {
          // height: calc(100vh);
        }

        @include MediumDevices {
          width: 50px;
        }

        @include SmallDevices {
          width: 30px;
        }

        @include XSmallDevices {
          display: none;
        }

        .week {
          width: 100%;
          height: 145px;
          padding: 10px;
          border: 1px solid var(--bs-gray-500);
          border-width: 1px 1px 0px 0px;
          display: flex;
          align-items: center;
          justify-content: center;

          @include LargeDevices {
            // height: calc(100vh);
          }

          @include MediumDevices {
            height: 100px;
            padding: 5px;
          }

          @include SmallDevices {
            height: 80px;
          }

          @include XSmallDevices {
          }

          .week__text {
            line-height: 1;
            font-weight: 700;
            color: var(--bs-stratose);
          }
        }
      }

      .date__wrap {
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        align-content: flex-start;

        .date-name__wrap {
          height: 30px;
          width: 100%;
          margin-left: -1px;
          display: flex;

          .date-name {
            width: calc(100% / 7);
            height: 100%;
            border: 1px solid var(--bs-gray-500);
            border-width: 0px 0px 0px 1px;
            display: flex;
            align-items: center;
            justify-content: center;
            margin: auto;

            .date-name__text {
              font-weight: 700;
              color: var(--bs-regal-blue);
              display: flex;
              justify-content: center;
              width: 100%;

              @include LargeDevices {
                // height: calc(100vh);
              }

              @include MediumDevices {
                font-size: 13px;
              }

              @include SmallDevices {
                font-size: 13px;
              }

              @include XSmallDevices {
                font-size: 13px;
              }
            }
          }
        }

        .date__container {
          width: calc((100% / 7));
          height: 145px;
          border: 1px solid var(--bs-gray-500);
          border-width: 1px 1px 0px 0px;
          display: flex;

          @include LargeDevices {
            // height: calc(100vh);
          }

          @include MediumDevices {
            height: 100px;
            padding: 2px;
          }

          @include SmallDevices {
            height: 60px;
            padding: 1px;
          }

          @include XSmallDevices {
            height: 60px;
            padding: 1px;
          }

          &:hover {
            background-color: var(--bs-gray-300);
            cursor: pointer;
          }

          &--fade {
            background-color: #e3e3e3 !important;

            &:hover {
              background-color: #e3e3e3 !important;
              cursor: not-allowed;
            }
          }

          &--active {
            background-color: var(--bs-bottcelli);

            &:hover {
              background-color: var(--bs-morning-glory);
              cursor: pointer;
            }
          }

          &--weekend {
            background-color: var(--bs-gray-300);
          }

          .date {
            padding: 3px;
            width: 100%;
            height: 100%;
            overflow: hidden;

            @include LargeDevices {
              // height: calc(100vh);
            }

            @include MediumDevices {
              padding: 0px;
            }

            @include SmallDevices {
              padding: 0px;
            }

            @include XSmallDevices {
              padding: 0px;
            }

            .date__text {
              color: var(--bs-stratos) !important;
              width: 100%;
              font-weight: 500;
              display: flex;
              justify-content: flex-end;
              user-select: none;

              @include LargeDevices {
                // height: calc(100vh);
              }

              @include MediumDevices {
                font-size: 14px;
              }

              @include SmallDevices {
                font-size: 14px;
              }

              @include XSmallDevices {
                font-size: 14px;
              }
            }

            .calendar-item {
              width: calc(100% - 4px);
              padding: 2px;
              line-height: 1.75;
              font-size: 14px;
              border-radius: 2px;
              color: white;
              display: flex;
              justify-content: space-between;
              align-items: center;

              @include LargeDevices {
                // height: calc(100vh);
              }

              @include MediumDevices {
                width: calc(100%);
                padding: 1px;
              }

              @include SmallDevices {
                width: calc(100%);
                padding: 1px;
              }

              @include XSmallDevices {
                width: calc(100%);
                padding: 1px;
              }

              :hover {
                cursor: default;
              }

              &--info {
                background-color: var(--bs-info);
              }

              &--success {
                background-color: var(--bs-success);
              }

              &--danger {
                background-color: var(--bs-danger);
              }

              &--warning {
                background-color: var(--bs-warning);
              }

              .calendar-item__text {
                width: 80%;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;

                @include LargeDevices {
                  // height: calc(100vh);
                }

                @include MediumDevices {
                  ///
                }

                @include SmallDevices {
                  // display: none;
                  width: 0;
                }

                @include XSmallDevices {
                  // display: none;
                  width: 0;
                }
              }

              .calendar-item__button {
                height: 16px;
                width: 16px;
                margin: 0 2px;
                color: white;

                @include LargeDevices {
                  // height: calc(100vh);
                }

                @include MediumDevices {
                  margin: 0px;
                }

                @include SmallDevices {
                  margin: 0px;
                }

                @include XSmallDevices {
                  margin: 0px;
                }

                .button__icon {
                  font-size: 14px;
                  cursor: pointer;

                  @include LargeDevices {
                    // height: calc(100vh);
                  }

                  @include MediumDevices {
                    font-size: 14px;
                  }

                  @include SmallDevices {
                    font-size: 13px;
                  }

                  @include XSmallDevices {
                    font-size: 12px;
                  }

                  &:hover {
                    color: var(--bs-gray-400);
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

.note-form__title {
  text-align: center;
  font-size: 20px;
  font-weight: 600;
  padding: 10px 15px 10px 15px !important;
}

.note-form__container {
  width: 600px;
  height: auto;
  background-color: white;
  padding: 16px;

  @include LargeDevices {
    width: 500px;
  }

  @include MediumDevices {
    width: 500px;
  }

  @include SmallDevices {
    width: 320px;
  }

  @include XSmallDevices {
    width: 320px;
  }

  .note-form__input {
    height: auto;

    .css-8ewcdo-MuiInputBase-root-MuiOutlinedInput-root {
      padding: 0;
    }
  }

  .note-form__label {
    margin-top: 16px;
    color: rgba(0, 0, 0, 0.6);
    font-size: 16px;
    font-weight: 600;
    margin-bottom: 3px;
  }

  .note-form__group {
    margin-top: 16px;
    display: flex;
    flex-wrap: wrap;

    .group__label {
      display: flex;
      align-items: center;
      font-size: 16px;
      font-weight: 600;
      margin-bottom: 3px;
    }

    .group__selection {
      display: flex;
      align-items: center;
    }

    .icon {
      width: 40px;
      height: 20px;
      margin-right: 5px;

      &--success {
        background-color: var(--bs-success);
      }

      &--danger {
        background-color: var(--bs-danger);
      }

      &--warning {
        background-color: var(--bs-warning);
      }

      &--info {
        background-color: var(--bs-info);
      }
    }

    .css-qzbt6i-MuiButtonBase-root-MuiIconButton-root-MuiAutocomplete-popupIndicator {
      display: none;
    }
  }
}

.scrollable {
  float: left;
  width: calc(100% - 2px);
  height: calc(100% - 20px);
  background: inherit;
  overflow: auto;
  overflow-x: hidden;
  padding-right: 2px;

  &::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 10px rgba(0, 0, 0, 0.3);
    border-radius: 10px;
    background-color: transparent;
  }

  &::-webkit-scrollbar {
    width: 8px;
    background-color: transparent;

    @include LargeDevices {
      // height: calc(100vh);
    }

    @include MediumDevices {
      width: 2px;
    }

    @include SmallDevices {
      width: 2px;
    }

    @include XSmallDevices {
      width: 2px;
    }
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 10px;
    -webkit-box-shadow: inset 0 0 10px rgba(0, 0, 0, 0.3);
    background-color: var(--bs-gray-400);
  }
}

.button {
  &-save {
    background-color: var(--bs-button-new) !important;
  }
  &-save:hover {
    background-color: var(--bs-regal-blue) !important;
  }

  &-delete {
    background-color: var(--bs-background-color-new) !important;
    color: var(--bs-stratos) !important;
    border: 1px solid var(--bs-bondi-blue) !important;
  }
  &-delete:hover {
    background-color: var(--bs-button-new) !important;
    color: white !important;
  }
}
