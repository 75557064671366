.reporttab__filter--button button {
  background-color: var(--bs-button-new) !important;
  color: white !important;
}
.reporttab__filter--button button:hover {
  background-color: var(--bs-regal-blue) !important;
}

.admin__timesheet {
  height: 100%;
  width: 100%;

  padding: 15px;
  padding-top: 5px;

  display: flex;
  flex-direction: column;

  &-navbar {
    height: fit-content;
    width: 100%;

    display: flex;

    .navbar__column {
      width: 50%;
      height: 100%;
    }
    // justify-content: space-between;
  }

  &-content {
    width: 100%;
    height: 100%;
  }

  &-loading {
    width: 85%;
    height: 87%;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
  }

  &-overviewtab {
    width: 100%;

    display: flex;

    .overview__right {
      width: 300px;

      height: 800px;
      margin-top: 15px;
      padding: 0 10px;

      display: flex;
      flex-direction: column;

      .overview__cateObjectContent {
        width: 100%;
        height: 730px;

        .overview__objectItem {
          width: 98%;
          // height: 40px;

          margin: 10px 0;
          // padding: 0 10px;
          background-color: rgb(211, 211, 211);
          box-shadow: 2px 2px 3px gray;

          display: flex;
          align-items: center;
          justify-content: space-between;

          cursor: pointer;
          transition: all ease-in-out 0.1s;
          &:hover {
            // background-color: rgb(211, 211, 211);
            transform: scale(1.02);
          }

          &-remove {
            height: 30px;
            width: 30px;
            margin: 0 8px;

            display: flex;
            justify-content: center;
            align-items: center;

            i {
              color: var(--bs-new-red);
            }
          }
          &-new {
            width: 100%;
            height: 40px;
            align-items: center;
            display: flex;
            justify-content: center;

            > i {
              color: var(--bs-new-green);
            }
          }
        }
      }
    }
    .overview__left {
      width: 100%;
      padding-right: 15px;

      flex: 1;
      display: flex;
      flex-direction: column;

      &-section {
        width: 100%;
        height: 300px;

        padding-top: 15px;
        display: flex;

        .overview__left-col01 {
          height: 100%;
          width: 360px;

          padding: 10px;
          position: relative;

          #admin__memberChart {
            width: 100%;
            height: 100%;
          }

          p {
            position: absolute;
            right: 10px;
            top: 10px;

            font-size: 38px;
            font-weight: 600;

            line-height: 1;
          }
        }

        .overview__left-col02 {
          flex: 1;
          width: 100%;

          padding: 10px;
          padding-bottom: 20px;
          margin-left: 15px;

          #admin__projectTimeChart {
            width: 100%;
            height: 100%;
          }
        }

        .overview__projectmanagement {
          width: 100%;
          height: 500px;

          display: flex;
          flex-direction: column;

          .overview-project__title {
            width: 100%;
            height: fit-content;
            padding: 10px;

            display: flex;
            justify-content: space-between;
            align-items: center;

            p {
              font-size: 20px;
              font-weight: 600;

              font-family: sans-serif;
            }

            .project__utils {
              display: flex;
              justify-content: flex-end;

              .button--create {
                background-color: var(--bs-button-new);

                &:hover {
                  background-color: var(--bs-regal-blue);
                }
              }
            }
          }
          .overview-project__content {
            width: 100%;

            padding: 0 10px;
            display: flex;
            flex-direction: column;

            .overview-project__header {
              width: 100%;
              height: 50px;
              background-color: var(--bs-regal-blue);
              color: white;
              box-shadow: 2px 2px 5px gray;
              display: flex;
              align-items: center;
            }

            .overview-project__list {
              width: 100%;
              height: 360px;

              // margin-top: 10px;
              .inactive {
                color: rgb(182, 182, 182);
                // font-style: italic;
                text-decoration: line-through;
              }
            }
            .overview-project__item {
              width: 100%;

              margin-top: 8px;
              padding: 3px 0;

              border-bottom: 1px solid rgb(219, 219, 219);
              display: flex;
              align-items: center;
              h6 {
                width: 100%;
                font-size: 16px;
                font-weight: 500;
                text-align: center;
              }

              .project__view-detail {
                cursor: pointer;
                text-decoration-line: underline;
                color: black;

                &:hover {
                  color: red;
                }
              }
            }

            .overview-project__col01,
            .overview-project__col03 {
              height: 100%;
              width: 10%;

              display: flex;
              align-items: center;
              justify-content: center;

              .overview-project__header-sort {
                width: 100%;

                border-right: 1px solid gray;
                display: flex;
                justify-content: center;
                align-items: center;

                > p {
                  width: fit-content;
                  font-weight: 600;
                }
              }
              .none__border {
                border: none;
              }
              .align-left {
                text-align: left;
                padding-left: 15px;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
              }
              .status__active {
                color: rgb(1, 185, 1);
              }

              .overview-project__sort {
                height: 25px;
                display: flex;
                flex-direction: column;
                margin-left: 5px;

                .sort__section {
                  cursor: pointer;
                  height: 50%;

                  display: flex;
                  justify-content: center;
                  align-items: center;

                  &:hover {
                    color: #d32f2f;
                  }
                }
              }
            }

            .overview-project__col03 {
              width: 30%;
            }
          }
        }
      }
    }
  }

  &--reporttab {
    height: 100%;

    .reporttab__filter {
      margin: 15px 0px;

      &--condition {
        .condition__dropdown {
          margin-top: 20px;
          margin-bottom: 15px;

          &--title__1 {
            font-size: 16px;
            font-weight: 600;
            display: flex;
            align-items: center;
            min-width: 125px;
            margin-top: 10px;
          }

          &--box__1 {
            max-width: 220px;
            grid-column-start: 1;
            grid-column-end: 1;
            grid-row-start: 2;
            grid-row-end: 2;
          }

          &--title__2 {
            font-size: 16px;
            font-weight: 600;
            display: flex;
            align-items: center;
            min-width: 150px;
            padding-right: 5%;
          }

          &--box__2 {
            max-width: 220px;
            grid-column-start: 1;
            grid-column-end: 1;
            grid-row-start: 4;
            grid-row-end: 4;
          }
        }

        .select-time__month {
          &--tiltle {
            font-size: 16px;
            font-weight: 600;
            display: flex;

            grid-column-start: 2;
            grid-column-end: 2;
            grid-row-start: 3;
            grid-row-end: 3;

            align-items: center;
          }
          &--select {
            display: flex;

            grid-column-start: 2;
            grid-column-end: 2;
            grid-row-start: 4;
            grid-row-end: 4;
          }
        }

        .select-time__date {
          &--title__1 {
            font-size: 16px;
            font-weight: 600;
            display: flex;
            align-items: center;
            grid-column-start: 2;
            grid-column-end: 2;
            grid-row-start: 3;
            grid-row-end: 3;

            align-items: center;
          }

          &-from {
            display: flex;
            margin-left: 10px;
          }

          &--title__2 {
            font-size: 16px;
            font-weight: 600;
            display: flex;

            // margin-right: 10px;

            align-items: center;
          }

          &-to {
            display: flex;

            grid-column-start: 3;
            grid-column-end: 3;
            grid-row-start: 4;
            grid-row-end: 4;
          }
        }

        .export-loading {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          margin-top: 6%;
          .text-loading {
            margin-top: 10px;
          }
        }
      }

      &--button {
        margin-top: 30px;
        margin-left: 31%;
        grid-column-start: 1;
        grid-column-end: 1;
        grid-row-start: 5;
        grid-row-end: 5;

        display: flex;
        align-items: flex-end;
      }
    }

    .reporttab__table {
      height: 85%;
      width: 100%;
      border-collapse: collapse;

      .css-15wwp11-MuiTableHead-root {
        background-color: #ddd;
        border-right: 1px solid rgb(32, 30, 30);
      }
      .MuiTableCell-body:first-child {
        border-left: 1px solid rgb(32, 30, 30);
      }
      .MuiTableCell-head:first-child {
        border-left: 1px solid rgb(32, 30, 30);
      }

      .MuiTableCell-root {
        border-top: 1px solid black;
        border-bottom: 1px solid black;
        border-right: 1px solid rgb(32, 30, 30);
        padding: 0px;
      }

      .MuiTableCell-head {
        font-weight: 650;
      }
      .table-header {
        padding: 10px 0px;
      }

      .MuiTableCell-body {
        height: 39px;
      }
    }
  }
}

.project-group {
  &__list {
    width: 100%;
    height: 100%;

    padding: 10px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
  &__header {
    width: 100%;
    display: flex;

    align-items: center;
    font-weight: 600;
  }
  &__content {
    width: 100%;
    height: 100px;
  }

  &__item {
    width: 100%;
    height: 30px;
    line-height: 30px;

    display: flex;
    justify-content: center;
    align-items: center;

    cursor: pointer;
    &:hover {
      background-color: rgb(196, 196, 196);
    }
  }
  &__footer {
    width: 100%;
    height: 35px;
    display: flex;

    justify-content: center;

    .button--new {
      background-color: var(--bs-button-new);

      &:hover {
        background-color: var(--bs-regal-blue);
      }
    }
  }

  &__col02 {
    width: 50%;
    height: 100%;

    text-align: left;

    white-space: nowrap;
    overflow: hidden !important;
    text-overflow: ellipsis;
  }
  &__col01 {
    width: 25%;
    height: 100%;

    text-align: center;
  }
}

@media (min-width: 360px) and (max-width: 639px) {
  .admin__timesheet {
    padding: 5px 15px !important;
  }
  .admin__timesheet-navbar {
    flex-direction: column-reverse;

    .navbar__column {
      width: 100%;
      display: flex;
      justify-content: center;
    }
  }
  .admin__timesheet-overviewtab {
    .overview__left {
      padding-right: 0px;
    }
    .overview__left-section {
      height: max-content !important;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      flex-wrap: wrap;

      .overview__left-col01,
      .overview__left-col02 {
        height: 280px !important;
        width: inherit;
        // margin-right: 15px;
      }
      .overview__left-col02 {
        margin-top: 15px;
        margin-left: 0px !important;
      }
      #admin__projectTimeChart {
        height: 280px !important;
      }
    }

    .overview-project__title {
      flex-direction: column;
      align-items: center;
      .project__utils {
        display: flex;
        justify-content: center;
        flex-direction: column;
        align-items: center;

        margin-top: 10px;
        .shared__searchfield {
          margin-bottom: 10px !important;
          width: 100% !important;
        }
        button {
          padding: 8px 10px !important;
        }
      }
    }

    .overview-project__header-sort {
      border-right: none !important;
      p {
        text-align: center;
        font-size: 14px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
      .overview-project__sort {
        margin-left: 1px !important;
      }
    }
    .overview__projectmanagement {
      height: 450px !important;
    }
    .overview-project__list {
      height: 250px !important;

      // margin-top: 10px;
      .overview-project__item {
        h6 {
          font-size: 14px !important;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }
    }
  }
  .overview__cateobject-dialog {
    height: 400px;
    width: 300px;
    padding: 10px;

    .overview__right {
      width: 290px;

      height: 310px;
      margin-top: 0px;
      padding: 0 10px;

      display: flex;
      flex-direction: column;

      .overview__cateObjectContent {
        width: 100%;
        height: 310px;

        .overview__objectItem {
          width: 98%;
          // height: 40px;

          margin: 10px 0;
          // padding: 0 10px;
          background-color: rgb(211, 211, 211);
          box-shadow: 2px 2px 3px gray;

          display: flex;
          align-items: center;
          justify-content: space-between;

          cursor: pointer;
          transition: all ease-in-out 0.1s;
          &:hover {
            // background-color: rgb(211, 211, 211);
            transform: scale(1.02);
          }

          &-remove {
            height: 30px;
            width: 30px;
            margin: 0 8px;

            display: flex;
            justify-content: center;
            align-items: center;

            i {
              color: #d32f2f;
            }
          }
          &-new {
            width: 100%;
            height: 40px;
            align-items: center;
            display: flex;
            justify-content: center;

            > i {
              color: green;
            }
          }
        }
      }
    }
  }

  .admin__timesheet--reporttab {
    height: 100%;
    width: 100%;

    .reporttab__filter {
      margin: 15px 0px;
      height: 100%;
      width: 100%;

      &--condition {
        width: 100%;
        height: 315px;
        .tag__content {
          width: 100% !important;
          height: 400px !important;
        }
        .tag__content-right {
          justify-content: flex-start !important;
          height: 370px !important;
        }

        .condition__export {
          width: 100%;
          display: inline;
          margin-left: 0px;

          grid-template-columns: 100%;
          grid-template-rows: 15% 20% 20% 20% 25%;
          column-gap: 10px;
          .condition__dropdown {
            margin-top: 20px;
            margin-bottom: 15px;

            &--title__1 {
              font-size: 16px;
              font-weight: 600;
              display: flex;
              align-items: center;
              min-width: 125px;
              margin-bottom: 5px;

              grid-column-start: 1;
              grid-column-end: 1;
              grid-row-start: 1;
              grid-row-end: 1;
            }

            &--box__1 {
              max-width: 220px;
              grid-column-start: 1;
              grid-column-end: 1;
              grid-row-start: 2;
              grid-row-end: 2;
            }

            &--title__2 {
              font-size: 16px;
              font-weight: 600;
              display: flex;
              align-items: center;
              min-width: 125px;

              grid-column-start: 1;
              grid-column-end: 1;
              grid-row-start: 3;
              grid-row-end: 3;
            }

            &--box__2 {
              max-width: 220px;
              grid-column-start: 1;
              grid-column-end: 1;
              grid-row-start: 4;
              grid-row-end: 4;
            }
          }

          .select-time__month {
            &--tiltle {
              font-size: 16px;
              font-weight: 600;
              display: flex;

              grid-column-start: 1;
              grid-column-end: 1;
              grid-row-start: 5;
              grid-row-end: 5;

              align-items: center;
            }
            &--select {
              display: flex;

              grid-column-start: 1;
              grid-column-end: 1;
              grid-row-start: 6;
              grid-row-end: 6;
            }
          }

          .select-time__date {
            &--title__1 {
              font-size: 16px;
              font-weight: 600;
              display: flex;
              align-items: center;
              grid-column-start: 1;
              grid-column-end: 1;
              grid-row-start: 5;
              grid-row-end: 5;

              align-items: center;
            }

            &-from {
              display: flex;
              grid-column-start: 1;
              grid-column-end: 1;
              grid-row-start: 6;
              grid-row-end: 6;
            }

            &--title__2 {
              font-size: 16px;
              font-weight: 600;
              display: flex;

              grid-column-start: 1;
              grid-column-end: 1;
              grid-row-start: 7;
              grid-row-end: 7;

              align-items: center;
            }

            &-to {
              display: flex;

              grid-column-start: 1;
              grid-column-end: 1;
              grid-row-start: 8;
              grid-row-end: 8;
            }
          }
          .export-loading {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            margin-top: 6%;
            .text-loading {
              margin-top: 10px;
            }
          }
        }
        .reporttab__filter--button {
          margin-bottom: 10%;
          grid-column-start: 1;
          grid-column-end: 1;
          grid-row-start: 5;
          grid-row-end: 5;

          display: flex;
          align-items: flex-end;
        }
      }
    }
  }
}

// Small devices (landscape phones, 640px and up)
@media (min-width: 640px) and (max-width: 767px) {
  .admin__timesheet-overviewtab {
    .overview__left {
      padding-right: 0px;
    }
    .overview__left-section {
      height: 280px !important;
      .overview__left-col01 {
        width: 40%;
      }
    }

    .project__utils {
      display: flex;
      justify-content: flex-end;

      .shared__searchfield {
        width: 150px !important;
      }
    }
    .overview-project__header-sort {
      border-right: none !important;
      p {
        text-align: center;
        font-size: 14px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
      .overview-project__sort {
        margin-left: 1px !important;
      }
    }
    .overview__projectmanagement {
      height: 270px !important;
    }
    .overview-project__list {
      height: 150px !important;

      // margin-top: 10px;
      .overview-project__item {
        h6 {
          font-size: 14px !important;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }
    }
  }
  .overview__cateobject-dialog {
    height: 230px;
    width: 300px;
    padding: 10px;

    .overview__right {
      width: 290px;

      height: 160px;
      margin-top: 0px;
      padding: 0 10px;

      display: flex;
      flex-direction: column;

      .overview__cateObjectContent {
        width: 100%;
        height: 160px;

        .overview__objectItem {
          width: 98%;
          // height: 40px;

          margin: 10px 0;
          // padding: 0 10px;
          background-color: rgb(211, 211, 211);
          box-shadow: 2px 2px 3px gray;

          display: flex;
          align-items: center;
          justify-content: space-between;

          cursor: pointer;
          transition: all ease-in-out 0.1s;
          &:hover {
            // background-color: rgb(211, 211, 211);
            transform: scale(1.02);
          }

          &-remove {
            height: 30px;
            width: 30px;
            margin: 0 8px;

            display: flex;
            justify-content: center;
            align-items: center;

            i {
              color: #d32f2f;
            }
          }
          &-new {
            width: 100%;
            height: 40px;
            align-items: center;
            display: flex;
            justify-content: center;

            > i {
              color: green;
            }
          }
        }
      }
    }
  }
}

// Medium devices (tablets, 768px and up)
@media (min-width: 768px) and (max-width: 1199px) {
  .admin__timesheet-overviewtab {
    .overview__left {
      padding-right: 0px;
    }
    .overview__left-section {
      height: 280px !important;
      .overview__left-col01 {
        width: 40%;
      }
    }

    .project__utils {
      display: flex;
      justify-content: flex-end;
      .shared__searchfield {
        width: 150px !important;
      }
    }
    .overview-project__header-sort {
      border-right: none !important;
      p {
        text-align: center;
        font-size: 14px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
      .overview-project__sort {
        margin-left: 1px !important;
      }
    }
    .overview__projectmanagement {
      height: 340px !important;

      .overview-project__content {
        overflow: auto;
        white-space: nowrap;
      }
    }
    .overview-project__list {
      height: 200px !important;

      // margin-top: 10px;
      .overview-project__item {
        h6 {
          font-size: 14px !important;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }
    }
  }
  .overview__cateobject-dialog {
    height: 500px;
    width: 300px;
    padding: 10px;

    .overview__right {
      width: 290px;

      height: 410px;
      margin-top: 0px;
      padding: 0 10px;

      display: flex;
      flex-direction: column;

      .overview__cateObjectContent {
        width: 100%;
        height: 410px;

        .overview__objectItem {
          width: 98%;
          // height: 40px;

          margin: 10px 0;
          // padding: 0 10px;
          background-color: rgb(211, 211, 211);
          box-shadow: 2px 2px 3px gray;

          display: flex;
          align-items: center;
          justify-content: space-between;

          cursor: pointer;
          transition: all ease-in-out 0.1s;
          &:hover {
            // background-color: rgb(211, 211, 211);
            transform: scale(1.02);
          }

          &-remove {
            height: 30px;
            width: 30px;
            margin: 0 8px;

            display: flex;
            justify-content: center;
            align-items: center;

            i {
              color: #d32f2f;
            }
          }
          &-new {
            width: 100%;
            height: 40px;
            align-items: center;
            display: flex;
            justify-content: center;

            > i {
              color: green;
            }
          }
        }
      }
    }
  }

  .admin__timesheet--reporttab {
    height: 100%;

    .reporttab__filter {
      margin: 15px 0px;

      &--condition {
        .tag__content {
          width: 100% !important;
          height: 275px !important;
        }
        .tag__content-data {
          .condition__export {
            width: 90%;
            display: grid;
            margin-left: 15px;

            grid-template-columns: 50% 25% 25%;
            grid-template-rows: 15% 20% 20% 20% 25%;
            column-gap: 10px;
            .condition__dropdown {
              margin-top: 20px;
              margin-bottom: 15px;

              &--title__1 {
                font-size: 16px;
                font-weight: 600;
                display: flex;
                align-items: center;
                min-width: 125px;
                margin-bottom: 5px;

                grid-column-start: 1;
                grid-column-end: 1;
                grid-row-start: 1;
                grid-row-end: 1;
              }

              &--box__1 {
                max-width: 220px;
                grid-column-start: 1;
                grid-column-end: 1;
                grid-row-start: 2;
                grid-row-end: 2;
              }

              &--title__2 {
                font-size: 16px;
                font-weight: 600;
                display: flex;
                align-items: center;
                min-width: 125px;

                grid-column-start: 1;
                grid-column-end: 1;
                grid-row-start: 3;
                grid-row-end: 3;
              }

              &--box__2 {
                max-width: 220px;
                grid-column-start: 1;
                grid-column-end: 1;
                grid-row-start: 4;
                grid-row-end: 4;
              }
            }

            .select-time__month {
              &--tiltle {
                font-size: 16px;
                font-weight: 600;
                display: flex;

                grid-column-start: 2;
                grid-column-end: 2;
                grid-row-start: 3;
                grid-row-end: 3;

                align-items: center;
              }
              &--select {
                display: flex;

                grid-column-start: 2;
                grid-column-end: 2;
                grid-row-start: 4;
                grid-row-end: 4;
              }
            }

            .select-time__date {
              &--title__1 {
                font-size: 16px;
                font-weight: 600;
                display: flex;
                align-items: center;
                grid-column-start: 2;
                grid-column-end: 2;
                grid-row-start: 3;
                grid-row-end: 3;

                align-items: center;
              }

              &-from {
                display: flex;
                grid-column-start: 2;
                grid-column-end: 2;
                grid-row-start: 4;
                grid-row-end: 4;
              }

              &--title__2 {
                font-size: 16px;
                font-weight: 600;
                display: flex;

                grid-column-start: 3;
                grid-column-end: 3;
                grid-row-start: 3;
                grid-row-end: 3;

                align-items: center;
              }

              &-to {
                display: flex;

                grid-column-start: 3;
                grid-column-end: 3;
                grid-row-start: 4;
                grid-row-end: 4;
              }
            }
          }

          .export-loading {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            margin-top: 6%;

            .text-loading {
              margin-top: 10px;
            }
          }
        }
      }

      &--button {
        margin-top: 30px;
        margin-left: 31%;
        grid-column-start: 1;
        grid-column-end: 1;
        grid-row-start: 5;
        grid-row-end: 5;

        display: flex;
        align-items: flex-end;
      }
    }
  }
}

// Large devices I (large desktops, 1200px and up)
@media (min-width: 1200px) and (max-width: 1399px) {
  .admin__timesheet-overviewtab {
    .overview__right {
      width: 250px;
      height: 620px;
    }
    .overview__cateObjectContent {
      height: 510px !important;
    }
    .overview__left-section {
      height: 280px !important;
    }
    .overview-project__header-sort {
      border-right: none !important;
      p {
        text-align: center;
        font-size: 14px;
      }
      .overview-project__sort {
        margin-left: 1px !important;
      }
    }
    .overview__projectmanagement {
      height: 340px !important;
    }
    .overview-project__list {
      height: 200px !important;

      // margin-top: 10px;
      .overview-project__item {
        h6 {
          font-size: 14px !important;
        }
      }
    }
  }

  .admin__timesheet--reporttab {
    height: 100%;

    .reporttab__filter {
      margin: 15px 0px;

      &--condition {
        .tag__content {
          width: 60% !important;
          height: 275px !important;
        }
        .tag__content-data {
          .condition__export {
            width: 90%;
            display: grid;
            margin-left: 15px;

            grid-template-columns: 40% 30% 30%;
            grid-template-rows: 15% 20% 20% 20% 25% 15% 20% 20% 20% 25%;
            column-gap: 10px;
            .condition__dropdown {
              margin-top: 20px;
              margin-bottom: 15px;

              &--title__1 {
                font-size: 16px;
                font-weight: 600;
                display: flex;
                align-items: center;
                min-width: 125px;
                margin-bottom: 5px;

                grid-column-start: 1;
                grid-column-end: 1;
                grid-row-start: 1;
                grid-row-end: 1;
              }

              &--box__1 {
                max-width: 220px;
                grid-column-start: 1;
                grid-column-end: 1;
                grid-row-start: 2;
                grid-row-end: 2;
              }

              &--title__2 {
                font-size: 16px;
                font-weight: 600;
                display: flex;
                align-items: center;
                min-width: 125px;

                grid-column-start: 1;
                grid-column-end: 1;
                grid-row-start: 3;
                grid-row-end: 3;
              }

              &--box__2 {
                max-width: 220px;
                grid-column-start: 1;
                grid-column-end: 1;
                grid-row-start: 4;
                grid-row-end: 4;
              }
            }

            .select-time__month {
              &--tiltle {
                font-size: 16px;
                font-weight: 600;
                display: flex;

                grid-column-start: 2;
                grid-column-end: 2;
                grid-row-start: 3;
                grid-row-end: 3;

                align-items: center;
              }
              &--select {
                display: flex;

                grid-column-start: 2;
                grid-column-end: 2;
                grid-row-start: 4;
                grid-row-end: 4;
              }
            }

            .select-time__date {
              &--title__1 {
                font-size: 16px;
                font-weight: 600;
                display: flex;
                align-items: center;
                grid-column-start: 2;
                grid-column-end: 2;
                grid-row-start: 3;
                grid-row-end: 3;

                align-items: center;
              }

              &-from {
                display: flex;
                grid-column-start: 2;
                grid-column-end: 2;
                grid-row-start: 4;
                grid-row-end: 4;
              }

              &--title__2 {
                font-size: 16px;
                font-weight: 600;
                display: flex;

                font-size: 16px;
                font-weight: 600;
                display: flex;

                align-items: center;
                padding-right: 5%;
              }

              &-to {
                display: flex;

                grid-column-start: 3;
                grid-column-end: 3;
                grid-row-start: 4;
                grid-row-end: 4;
              }
            }
          }
        }
      }

      &--button {
        margin-top: 30px;
        margin-left: 31%;
        grid-column-start: 1;
        grid-column-end: 1;
        grid-row-start: 5;
        grid-row-end: 5;

        display: flex;
        align-items: flex-end;
      }
    }
  }
}

// Large devices II (larger desktops, 1400px and up)
@media (min-width: 1400px) and (max-width: 1919px) {
  .admin__timesheet--reporttab {
    height: 100%;

    .reporttab__filter {
      margin: 15px 0px;

      &--condition {
        .tag__content {
          width: 55% !important;
        }
        .tag__content-data {
          .condition__export {
            width: 90%;
            display: grid;
            margin-left: 15px;

            grid-template-columns: 40% 30% 30%;
            grid-template-rows: 15% 20% 20% 20% 25%;
            column-gap: 10px;
            .condition__dropdown {
              margin-top: 20px;
              margin-bottom: 15px;

              &--title__1 {
                font-size: 16px;
                font-weight: 600;
                display: flex;
                align-items: center;
                min-width: 125px;
                margin-bottom: 5px;

                grid-column-start: 1;
                grid-column-end: 1;
                grid-row-start: 1;
                grid-row-end: 1;
              }

              &--box__1 {
                max-width: 220px;
                grid-column-start: 1;
                grid-column-end: 1;
                grid-row-start: 2;
                grid-row-end: 2;
              }

              &--title__2 {
                font-size: 16px;
                font-weight: 600;
                display: flex;
                align-items: center;
                font-size: 16px;
                font-weight: 600;
                display: flex;

                align-items: center;
                padding-right: 5%;
              }

              &--box__2 {
                max-width: 220px;
                grid-column-start: 1;
                grid-column-end: 1;
                grid-row-start: 4;
                grid-row-end: 4;
              }
            }

            .select-time__month {
              &--tiltle {
                font-size: 16px;
                font-weight: 600;
                display: flex;

                grid-column-start: 2;
                grid-column-end: 2;
                grid-row-start: 3;
                grid-row-end: 3;

                align-items: center;
              }
              &--select {
                display: flex;

                grid-column-start: 2;
                grid-column-end: 2;
                grid-row-start: 4;
                grid-row-end: 4;
              }
            }

            .select-time__date {
              &--title__1 {
                font-size: 16px;
                font-weight: 600;
                display: flex;
                align-items: center;
                grid-column-start: 2;
                grid-column-end: 2;
                grid-row-start: 3;
                grid-row-end: 3;

                align-items: center;
              }

              &-from {
                display: flex;
                grid-column-start: 2;
                grid-column-end: 2;
                grid-row-start: 4;
                grid-row-end: 4;
              }

              &--title__2 {
                font-size: 16px;
                font-weight: 600;
                display: flex;

                font-size: 16px;
                font-weight: 600;
                display: flex;

                align-items: center;
                padding-right: 5%;
              }

              &-to {
                display: flex;

                grid-column-start: 3;
                grid-column-end: 3;
                grid-row-start: 4;
                grid-row-end: 4;
              }
            }
          }

          .export-loading {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            margin-top: 6%;

            .text-loading {
              margin-top: 10px;
            }
          }
        }
      }

      &--button {
        margin-top: 30px;
        margin-left: 31%;
        grid-column-start: 1;
        grid-column-end: 1;
        grid-row-start: 5;
        grid-row-end: 5;

        display: flex;
        align-items: flex-end;
      }
    }
  }
}
