.note-form__group {
  // margin-top: 16px;
  display: flex;
  justify-self: center;

  .group__label {
    display: flex;
    align-items: center;
  }

  .group__selection {
    display: flex;
    align-items: center;
  }

  .icon {
    width: 40px;
    height: 20px;
    margin-right: 5px;

    &--success {
      background-color: var(--bs-success);
    }

    &--danger {
      background-color: var(--bs-danger);
    }

    &--warning {
      background-color: var(--bs-warning);
    }

    &--info {
      background-color: var(--bs-info);
    }
  }
}
