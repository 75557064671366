$XSmall: 360px;
$Small: 640px;
$Medium: 768px;
$Large: 1200px;
$XLarge: 1400px;

@mixin XSmallDevices {
  @media (min-width: #{$XSmall}) and (max-width: #{$Small - 1px}) {
    @content;
  }
}

@mixin SmallDevices {
  @media (min-width: #{$Small}) and (max-width: #{$Medium - 1px}) {
    @content;
  }
}

@mixin MediumDevices {
  @media (min-width: #{$Medium}) and (max-width: #{$Large - 1px}) {
    @content;
  }
}

@mixin LargeDevices {
  @media (min-width: #{$Large}) and (max-width: #{$XLarge - 1px}) {
    @content;
  }
}

@mixin XLargeDevices {
  @media (min-width: #{$XLarge}) {
    @content;
  }
}

.button {
  text-transform: none !important;

  &.--primary {
    background-color: var(--bs-primary);
    color: white;

    &:hover {
      background-color: var(--bs-primary);
    }
  }

  &.--success {
    background-color: var(--bs-button-new);

    &:hover {
      background-color: var(--bs-regal-blue);
    }
  }

  &.--warning {
    //background-color: var(--bs-warning);
    background-color: var(--bs-background-color-new);
    color: var(--bs-stratos);
    border: 1px solid var(--bs-bondi-blue) !important;
    &:hover {
      //background-color: #dba446;
      background-color: var(--bs-button-new);
      color: white;
    }
  }

  &.--danger {
    background-color: var(--bs-gray-new);
    color: var(--bs-stratos) !important;

    &:hover {
      background-color: var(--bs-button-new);
      color: var(--bs-gray-new) !important;
    }
  }

  &.--info {
    //background-color: var(--bs-info);
    background-color: var(--bs-background-color-new);
    color: var(--bs-stratos);
    border: 1px solid var(--bs-bondi-blue) !important;

    &:hover {
      //background-color: #3898ec;
      background-color: var(--bs-button-new);
      color: white;
    }
  }
}

.color {
  &--danger {
    color: var(--bs-danger) !important;
  }

  &--success {
    color: var(--bs-regal-blue) !important;
  }

  &--info {
    color: var(--bs-stratos) !important;
  }

  &--primary {
    color: var(--bs-primary) !important;
  }
}
.scrollable {
  float: left;
  width: calc(100%);
  height: calc(100%);
  margin-top: 5px;
  background: inherit;
  overflow: auto;
  overflow-x: hidden;

  &::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 10px rgba(0, 0, 0, 0.3);
    border-radius: 10px;
    background-color: transparent;
  }

  &::-webkit-scrollbar {
    width: 8px;
    background-color: transparent;

    @include LargeDevices {
      // height: calc(100vh);
    }

    @include MediumDevices {
      width: 2px;
    }

    @include SmallDevices {
      width: 2px;
    }

    @include XSmallDevices {
      width: 2px;
    }
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 10px;
    -webkit-box-shadow: inset 0 0 10px rgba(0, 0, 0, 0.3);
    background-color: var(--bs-gray-400);
  }
}

.user__wrap {
  height: 100%;
  width: 100%;

  .user__container {
    width: 72.5%;
    height: 100%;

    display: flex;
    align-items: center;
    justify-content: flex-start;
    z-index: 1;
    border: 1px solid #bfbfc1;

    border-radius: 4px;

    @include LargeDevices {
      width: 290px;
    }

    @include MediumDevices {
      margin: auto;
      height: 50px;
      width: 50%;
      justify-content: center;
    }

    @include SmallDevices {
      margin: auto;
      height: 45px;
      width: 70%;
      justify-content: center;
    }

    @include XSmallDevices {
      margin: auto;
      height: 45px;
      width: 75%;
      justify-content: center;
    }

    .user__avatar {
      width: 40px;
      height: 40px;
      margin-left: 10px;

      @include LargeDevices {
        width: 36px;
        height: 36px;
      }

      @include MediumDevices {
        width: 40px;
        height: 40px;
      }

      @include SmallDevices {
        width: 36px;
        height: 36px;
      }

      @include XSmallDevices {
        width: 36px;
        height: 36px;
      }
    }

    .user__text {
      // font-size: 20px;
      font-weight: 700;
      white-space: nowrap;
      overflow: hidden !important;
      text-overflow: ellipsis;
      width: 68%;

      @include LargeDevices {
        font-size: 18px;
      }

      @include MediumDevices {
        font-size: 16px;
      }

      @include SmallDevices {
        font-size: 16px;
      }

      @include XSmallDevices {
        font-size: 16px;
      }
    }

    .user__button {
      width: 46px;
      height: 46px;

      @include LargeDevices {
        width: 40px;
        height: 40px;
      }

      @include MediumDevices {
        width: 36px;
        height: 36px;
      }

      @include SmallDevices {
        width: 34px;
        height: 34px;
      }

      @include XSmallDevices {
        width: 32px;
        height: 32px;
      }
    }
  }

  .menu_user__wrap {
    display: flex;
    flex-wrap: wrap;

    :not(style) {
      margin: 1px;
      height: auto;
      position: absolute;
    }
  }

  .menu_user {
    width: 300px;
    z-index: 99;

    @include LargeDevices {
      width: 280px;
    }

    @include MediumDevices {
      width: 280px;
      margin: auto;
    }

    @include SmallDevices {
      width: 60%;
      margin: auto;
    }

    @include XSmallDevices {
      width: 60%;
      margin: auto;
    }

    .menu_user__search {
      height: 40px;
      width: calc(100% - 2px);
      margin: 1px;
      display: flex;
      align-items: center;
      padding: 1px;
      background-color: white;
      // border: 1px solid var(--bs-gray-700);

      font-size: 18px;

      @include LargeDevices {
        font-size: 18px;
        padding: 8px;
      }

      @include MediumDevices {
        font-size: 16px;
        padding: 5px;
      }

      @include SmallDevices {
        font-size: 16px;
        padding: 5px;
      }

      @include XSmallDevices {
        font-size: 16px;
        padding: 5px;
      }

      .search__icon {
        width: 40px;

        @include LargeDevices {
          width: 36px;
        }

        @include MediumDevices {
          width: 30px;
        }

        @include SmallDevices {
          width: 26px;
        }

        @include XSmallDevices {
          width: 20px;
        }
      }

      .search__input {
        width: calc(100% - 40px);
        // padding-left: 14px;

        @include LargeDevices {
          width: calc(100% - 36px);
        }

        @include MediumDevices {
          width: calc(100% - 30px);
        }

        @include SmallDevices {
          width: calc(100% - 26px);
        }

        @include XSmallDevices {
          width: calc(100% - 20px);
        }
      }
    }

    .menu_user__container {
      background-color: white;
      max-height: 400px;
      border-radius: 10px;
      overflow: auto;
      overflow-x: hidden;

      .menu_user__item {
        width: 100%;

        .item__avatar {
          width: 36px;
          height: 36px;

          @include LargeDevices {
            width: 32px;
            height: 32px;
          }

          @include MediumDevices {
            width: 32px;
            height: 32px;
            max-height: 600px;
          }

          @include SmallDevices {
            width: 32px;
            height: 32px;
            max-height: 600px;
          }

          @include XSmallDevices {
            width: 32px;
            height: 32px;
            max-height: 600px;
          }
        }

        .item__text {
          font-size: 16px;
          font-weight: 600;
          padding: 5px;
          white-space: nowrap;
          overflow: hidden !important;
          text-overflow: ellipsis;

          @include LargeDevices {
          }

          @include MediumDevices {
            font-size: 16px;
          }

          @include SmallDevices {
            font-size: 16px;
          }

          @include XSmallDevices {
            font-size: 16px;
          }
        }
      }
    }
  }

  .button {
    &:hover {
      background-color: var(--bs-gray-700);
    }

    .button__icon {
      font-size: 30px;
    }
  }
}
