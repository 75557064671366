.navigation__item {
  height: 33px;

  padding-left: 20px;
  margin: 4px 0;

  display: flex;
  align-items: center;
  cursor: pointer;

  &:hover {
    background-color: #7e7e7e;
    transition: all ease-in-out 0.1s;
  }

  &.active {
    background-color: #646b7e;
  }

  &.disable {
    color: gray;
    cursor: not-allowed;
    // background-color: #2a3042;
  }

  &-icon {
    width: 20px;
    i {
      font-size: 16px;
      color: inherit;
    }
  }

  p {
    margin-left: 8px;
    font-size: 14px;
    font-weight: 500;
  }
}
