.admin__user {
  height: 100%;
  width: 100%;

  padding: 15px;
  padding-top: 5px;

  display: flex;
  flex-direction: column;

  &--content {
    height: 100%;

    .user-content__user {
      height: 100%;

      &--selection {
        display: flex;
        margin: 15px 0px;
        width: 100%;

        .team-selection {
          display: flex;
          flex-grow: 1;
        }

        .user-summary {
          display: flex;
          flex-grow: 20;

          align-items: center;
        }

        .css-qepv40-MuiInputBase-root-MuiOutlinedInput-root {
          left: 0px;
        }
        
        .user-create {
          display: flex;
          justify-content: flex-end;
          flex-grow: 6;

          .button--user--create {
            background-color: var(--bs-button-new) !important;
          
            &:hover {
              background-color: var(--bs-regal-blue) !important;
            }
          }
        }
      }

      &--table {
        background-color: white;
  
        padding: 15px;
        height: 92%;

        .data-table {
          height: 100%;

          .MuiDataGrid-header {
            background-color: var(--bs-regal-blue);
            border-right: 1px solid rgb(32, 30, 30);
          }
          .MuiDataGrid-header:first-child {
            border-left: 1px solid rgb(32, 30, 30);
          }

          .MuiDataGrid-columnHeaders {
            border-top: 1px solid black;
            border-bottom: 1px solid black;
          }

          .MuiDataGrid-columnHeaderTitle {
            font-weight: bold;
            color: white;
          }
          .MuiDataGrid-virtualScroller {
            font-weight: 650;
          }
          .MuiDataGrid-tablecell {
            border-right: 1px solid rgb(32, 30, 30);
            border-bottom: 1px solid rgb(32, 30, 30) !important;
          }
          .MuiDataGrid-tablecell:first-child {
            border-left: 1px solid rgb(32, 30, 30);
          }
          .MuiDataGrid-columnSeparator {
            visibility: hidden;
          }

          .status--active {
            color: #28a745;
          }

          .status--inactive {
            color: #030403;
          }
        }
      }
    }

    .user-content__group {
      height: 100%;

      &--selection {
        display: flex;
        margin: 15px 0px;
        width: 100%;

        .group-selection {
          display: flex;
          flex-grow: 1;

          
        }

        .group-summary {
          display: flex;
          flex-grow: 20;

          align-items: center;
        }

        .css-qepv40-MuiInputBase-root-MuiOutlinedInput-root {
          left: 0px;
        }
        .css-luznnn-MuiInputBase-root-MuiOutlinedInput-root-MuiSelect-root{
          color: var(--bs-stratos) !important; 
        }
        .group-create {
          display: flex;
          justify-content: flex-end;
          flex-grow: 6;
          
          .button--create {
            background-color: var(--bs-button-new);
          
            &:hover {
              background-color: var(--bs-regal-blue);
            }
          }
        }
      }

      &--table {
        background-color: white;
  
        padding: 15px;
        height: 92%;


        .data-table {
          height: 100%;

          // .MuiDataGrid-row:nth-child(even) {
          //   background-color: aliceblue;
          // }
          .MuiDataGrid-header {
            background-color: var(--bs-regal-blue);
            border-right: 1px solid rgb(32, 30, 30);
          }
          .MuiDataGrid-header:first-child {
            border-left: 1px solid rgb(32, 30, 30);
          }

          .MuiDataGrid-columnHeaders {
            border-top: 1px solid black;
            border-bottom: 1px solid black;
          }

          .MuiDataGrid-columnHeaderTitle {
            font-weight: bold;
            color: white;
          }
          .MuiDataGrid-virtualScroller {
            font-weight: 650;
          }
          .MuiDataGrid-tablecell {
            border-right: 1px solid rgb(32, 30, 30);
            border-bottom: 1px solid rgb(32, 30, 30) !important;
          }
          .MuiDataGrid-tablecell:first-child {
            border-left: 1px solid rgb(32, 30, 30);
          }
          .MuiDataGrid-columnSeparator {
            visibility: hidden;
          }

          .status--active {
            color: #28a745;
          }

          .status--inactive {
            color: #030403;
          }
        }
      }
    }
  }
}

/****** RESPONSIVE ******/
// X-Small devices (portrait phones, 360px and up
@media only screen and (min-width: 360px) and (max-width: 639px) {
  .admin__user {
    height: 100%;
    width: 100%;

    padding: 15px;
    padding-top: 5px;

    display: flex;
    flex-direction: column;

    &--content {
      height: 80%;
      .css-rwf70c-MuiButtonBase-root-MuiTab-root.Mui-selected{
        color: var(--bs-stratos);
      }
      .user-content__user {
        height: 100%;

        &--selection {
          display: block;
          margin: 15px 0px;
          width: 100%;

          .team-selection {
            display: flex;
          }

          .user-summary {
            display: flex;
            align-items: center;
            margin: 10px 0px;
          }

          .user-create {
            display: flex;
            justify-content: flex-start;
            min-height: 40px;

            .button--user--create {
              background-color: var(--bs-button-new) !important;
            
              &:hover {
                background-color: var(--bs-regal-blue) !important;
              }
            }
          }
        }
      }

      .user-content__group {
        height: 100%;

        &--selection {
          display: block;
          margin: 15px 0px;
          width: 100%;

          .group-selection {
            display: flex;
          }

          .group-summary {
            display: flex;
            align-items: center;
            margin: 10px 0px;
          }

          .group-create {
            display: flex;
            justify-content: flex-start;
            min-height: 40px;

            .button--create {
              background-color: var(--bs-button-new);
            
              &:hover {
                background-color: var(--bs-regal-blue);
              }
            }
          }
        }
      }
    }
  }
}
