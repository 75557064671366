$XSmall: 360px;
$Small: 640px;
$Medium: 768px;
$Large: 1200px;
$XLarge: 1400px;

@mixin XSmallDevices {
  @media (min-width: #{$XSmall}) and (max-width: #{$Small - 1px}) {
    @content;
  }
}

@mixin SmallDevices {
  @media (min-width: #{$Small}) and (max-width: #{$Medium - 1px}) {
    @content;
  }
}

@mixin MediumDevices {
  @media (min-width: #{$Medium}) and (max-width: #{$Large - 1px}) {
    @content;
  }
}

@mixin LargeDevices {
  @media (min-width: #{$Large}) and (max-width: #{$XLarge - 1px}) {
    @content;
  }
}

@mixin XLargeDevices {
  @media (min-width: #{$XLarge}) {
    @content;
  }
}

.customized-calendar {
  width: 100%;
  height: 100%;

  .calendar__header {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    color: var(--bs-regal-blue);

    .calendar__text {
      height: 100%;
      font-size: 16px;
      list-style: 0.75;
      font-weight: 600;
    }
  }

  .calendar__week {
    width: 100%;
    display: flex;

    .week__cell {
      width: calc(100% / 7);
      display: flex;
      font-weight: 600;
      justify-content: center;
      font-size: 13px;
      color: var(--bs-regal-blue);
      @include LargeDevices {
        font-size: 11px;
      }

      @include MediumDevices {
        font-size: 11px;
      }

      @include SmallDevices {
        font-size: 11px;
      }

      @include XSmallDevices {
        font-size: 11px;
      }
    }
  }

  .calendar__body {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    align-content: space-around;
    height: 65%;

    padding-bottom: 10px;

    .day__cell {
      display: flex;
      justify-content: center;
      width: calc(100% / 7);

      .day__text {
        width: 20px;
        height: 20px;
        display: flex;
        font-size: 14px;
        justify-content: center;
        align-items: center;
        padding: 2px;

        @include LargeDevices {
          font-size: 12px;
        }

        @include MediumDevices {
          font-size: 12px;
        }

        @include SmallDevices {
          font-size: 12px;
        }

        @include XSmallDevices {
          font-size: 12px;
        }

        &:hover {
          cursor: pointer;
          background-color: var(--bs-gray-200);
        }

        &.--faded {
          color: var(--bs-gray-400);
        }

        &.--active {
          color: white;
          background-color: var(--bs-regal-blue);
        }

        &.--selected {
          border: 1px solid gray;
        }
      }
    }
  }
}