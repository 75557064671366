.leaveform {
  display: flex;
  flex-direction: column;

  height: 100%;
  width: 100%;
  padding: 15px;

  &__leaveform-card {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;

    .card__content {
      display: flex;
      position: relative;
      //justify-content: space-between;
      height: 90%;

      &-number {
        display: flex;
        flex-grow: 1;
        line-height: 0.85;

        font-size: 90px;
        font-weight: 400;

        color: var(--bs-stratos);
      }
      &-days {
        flex-grow: 1;
        font-size: 16px;
        display: flex;
        align-items: flex-end;
        margin-bottom: -7px;
        line-height: 0.85;

        font-weight: 400;

      }
      &-leave-content {
        position: absolute;
        top: 10px;
        right: 3px;
        padding-left: 20%;
        line-height: 0.85;
        font-weight: 400;
      }
      &-leave-content > div {
        margin-top: 10%;
      }

      &-leave-content,
      .p {
        line-height: 15px;
      }
    }
  }

  &__leaveform-selector {
    display: flex;
    align-items: center;
    flex-direction: row;
    justify-content: space-between;

    padding: 15px 0px;

    .button {
      flex-grow: 1;
      display: flex;

      &__export {
        justify-content: flex-end;
        &-pdf {
          background-color: var(--bs-background-color-new);
          color: var(--bs-stratos);
          border: 1px solid  var(--bs-bondi-blue) !important;
        };
        &-pdf:hover {
          background-color: var(--bs-button-new);
          color: white;
        }
      }
      &__normal { 
        &-new{
          background-color: var(--bs-button-new)
        };

        &-new:hover {
          background-color: var(--bs-regal-blue);
        }
      }

    }

    .year-selector {
      flex-grow: 2;

      display: flex;
      align-items: center;
      justify-content: center;

      > .arrow-icon {
        cursor: pointer;
        color: var(--bs-stratos);
        font-size: 50px;
      }

      &__label {
        font-size: 50px;
        color: var(--bs-stratos);
      }
      .MuiOutlinedInput-input {
        text-align: center;
        margin-left: 10%;

        color: red;
        font-weight: bold;
        font-size: 25px;
      }
    }
  }

  &__leaveform-table {
    display: block;
    background-color: white;

    padding: 15px;
    height: 75%;

    // .MuiDataGrid-row:nth-child(even) {
    //   background-color: aliceblue;
    // }
    .MuiDataGrid-header {
      background-color: var(--bs-regal-blue);
      color: white;
      border-right: 1px solid rgb(32, 30, 30);
    }
    .MuiDataGrid-header:first-child {
      border-left: 1px solid rgb(32, 30, 30);
    }

    .MuiDataGrid-columnHeaders {
      border-top: 1px solid black;
      border-bottom: 1px solid black;
    }

    .MuiDataGrid-columnHeaderTitle {
      font-weight: bold;
    }
    .MuiDataGrid-virtualScroller {
      font-weight: 650;
    }
    .MuiDataGrid-tablecell {
      border-right: 1px solid rgb(32, 30, 30);
      border-bottom: 1px solid rgb(32, 30, 30) !important;
    }
    .MuiDataGrid-tablecell:first-child {
      border-left: 1px solid rgb(32, 30, 30);
    }
    .MuiDataGrid-columnSeparator {
      visibility: hidden;
    }
    .status--approved {
      color: var(--bs-approved);
    }

    .status--rejected {
      color: var(--bs-rejected);
    }

    .status--pending {
      color: var(--bs-pending);
    }
  }
}

/****** RESPONSIVE ******/
// X-Small devices (portrait phones, 360px and up
@media only screen and (min-width: 360px) and (max-width: 639px) {
  .leaveform {
    &__infor {
      display: flex;
      flex-direction: column;

      width: 100%;

      margin-top: 10px;
    }
    .tag__content {
      height: 100% !important;
      width: 100% !important;
    }
    .card__content-leave-content > div {
      font-size: 14px;
    }

    .year-selector {
      display: flex;
      align-items: center;
      justify-content: center;
      margin: 0px 10px;

      width: 60%;

      &__label {
        font-size: 25px;
        width: 200%;
        padding-bottom: 4px;
      }

      > .arrow-icon {
        cursor: pointer;
        color: #0b5394;
        font-size: 50px;
      }
    }
  }
}

// Small devices (landscape phones, 640px and up)
@media only screen and (min-width: 640px) and (max-width: 767px) {
  .leaveform {
    &__infor {
      display: flex;
      flex-direction: column;

      width: 100%;

      margin-top: 10px;
    }

    .tag__content {
      height: 100% !important;
      width: 100% !important;
    }

    .year-selector {
      display: flex;
      align-items: center;
      justify-content: center;
      margin: 0px 10px;

      width: 60%;

      &__label {
        font-size: 25px;
        width: 200%;
        padding-bottom: 4px;
      }
    }
  }
}

// Medium devices (tablets, 768px and up)
@media only screen and (min-width: 768px) and (max-width: 1199px) {
  .leaveform {
    &__infor {
      display: flex;
      flex-direction: column;

      width: 100%;

      margin-top: 10px;
    }

    .tag__content {
      height: 100% !important;
      width: 100% !important;
    }

    .card__content-leave-content > div {
      font-size: 18px;
    }

    .year-selector {
      display: flex;
      align-items: center;
      justify-content: center;
      margin: 0px 10px;

      width: 60%;

      &__label {
        font-size: 25px;
        padding-bottom: 4px;
      }
    }
  }
}

// Large devices I (large desktops, 1200px and up)
@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .leaveform {
    &__infor {
      display: flex;
      flex-direction: column;

      width: 100%;

      margin-top: 10px;
    }
    .tag__content {
      height: 100% !important;
      width: 45% !important;
    }
  }
}

// // Large devices II (larger desktops, 1400px and up)
@media (min-width: 1400px) {
  .request {
    &__infor {
      display: flex;
      flex-direction: column;

      width: 100%;

      margin-top: 10px;
    }

    .tag__content {
      height: 100% !important;
      width: 45% !important;
    }
  }
}
