:root {
  --bs-stratos: #001B48;
  --bs-regal-blue: #02457A;
  --bs-bondi-blue: #018abe;
  --bs-button-new: #2383bb;
  --bs-morning-glory: #97cadb;
  --bs-bottcelli: #e1eff8;
  --bs-gray-new: #F5F5F5;
  --bs-background-color-new: #f8f8fb;
  --bs-new-green: #93bc39;
  --bs-new-red: #ee3e54;
  --bs-approved: #0d7d0e;
  --bs-rejected: #b73636;
  --bs-pending: #e4ba02;
  --bs-weekday: #39aa14;
  --bs-weekend: #ff9331;
  --bs-night-overtime: #f53b02;
  --bs-public-holiday: #88140e;


  
  --bs-blue: #556ee6;
  --bs-indigo: #564ab1;
  --bs-purple: #6f42c1;
  --bs-red: #f46a6a;
  --bs-orange: #f1734f;
  --bs-yellow: #f1b44c;
  --bs-green: #34c38f;
  --bs-teal: #050505;
  --bs-cyan: #50a5f1;
  --bs-white: #fff;
  --bs-gray: #74788d;
  --bs-gray-dark: #343a40;
  --bs-gray-100: #f8f9fa;
  --bs-gray-200: #eff2f7;
  --bs-gray-300: #f6f6f6;
  --bs-gray-400: #ced4da;
  --bs-gray-500: #adb5bd;
  --bs-gray-600: #74788d;
  --bs-gray-700: #495057;
  --bs-gray-800: #343a40;
  --bs-gray-900: #212529;
  --bs-primary: #556ee6;
  --bs-secondary: #74788d;
  --bs-success: #00C851;
  --bs-info: #33B5E5;
  --bs-warning: #FFBB33;
  --bs-danger: #FF4444;
  --bs-pink: #e83e8c;
  --bs-light: #eff2f7;
  --bs-dark: #343a40;
  --bs-primary-rgb: 85, 110, 230;
  --bs-secondary-rgb: 116, 120, 141;
  --bs-success-rgb: 52, 195, 143;
  --bs-info-rgb: 80, 165, 241;
  --bs-warning-rgb: 241, 180, 76;
  --bs-danger-rgb: 244, 106, 106;
  --bs-pink-rgb: 232, 62, 140;
  --bs-light-rgb: 239, 242, 247;
  --bs-dark-rgb: 52, 58, 64;
  --bs-white-rgb: 255, 255, 255;
  --bs-black-rgb: 0, 0, 0;
  --bs-body-rgb: 73, 80, 87;
  --bs-font-sans-serif: "Poppins", sans-serif;
  --bs-font-monospace: SFMono-Regular, Menlo, Monaco, Consolas,
    "Liberation Mono", "Courier New", monospace;
  --bs-gradient: linear-gradient(
    180deg,
    hsla(0, 0%, 100%, 0.15),
    hsla(0, 0%, 100%, 0)
  );
  --bs-body-font-family: var(--bs-font-sans-serif);
  --bs-body-font-size: 0.8125rem;
  --bs-body-font-weight: 400;
  --bs-body-line-height: 1.5;
  --bs-body-color: #495057;
  --bs-body-bg: #f8f8fb;
}
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}


@media (min-width: 360px) and (max-width: 639px) {
}

// Small devices (landscape phones, 640px and up)
@media (min-width: 640px) and (max-width: 767px) {
}

// Medium devices (tablets, 768px and up)
@media (min-width: 768px) and (max-width: 1199px) {
}

// Large devices I (large desktops, 1200px and up)
@media (min-width: 1200px) and (max-width: 1399px) {
}

// Large devices II (larger desktops, 1400px and up)
@media (min-width: 1400px) {
}
