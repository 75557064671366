.menutopbar {
  height: 100%;
  padding: 0 15px;

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  &__left {
    display: flex;
    align-items: center;

    i {
      cursor: pointer;
      color: var(--bs-stratos);

      transition: all ease-in-out 0.2s;
      transform: scale(1.1);

      &:hover {
        transform: scale(1.2);
      }
    }
  }

  &__right {
    display: flex;
    align-items: center;
    margin-right: 20px;

    &-status {
      margin: 0 10px;
    }
    &-notification {
      height: 200px;
      width: 200px;
      padding: 10px;
    }
  }

  &__status {
    display: flex;
    flex-direction: row;

    align-items: center;
  }

  &__circle {
    height: 10px;
    width: 10px;
    border-radius: 50%;
    margin-right: 10px;

    border: 1px solid rgb(168, 168, 168);
  }
}
