$XSmall: 360px;
$Small: 640px;
$Medium: 768px;
$Large: 1200px;
$XLarge: 1400px;

@mixin XSmallDevices {
    @media (min-width: #{$XSmall}) and (max-width: #{$Small - 1px}) {
        @content;
    }
}

@mixin SmallDevices {
    @media (min-width: #{$Small}) and (max-width: #{$Medium - 1px}) {
        @content;
    }
}

@mixin MediumDevices {
    @media (min-width: #{$Medium}) and (max-width: #{$Large - 1px}) {
        @content;
    }
}

@mixin LargeDevices {
    @media (min-width: #{$Large}) and (max-width: #{$XLarge - 1px}) {
        @content;
    }
}

@mixin XLargeDevices {
    @media (min-width: #{$XLarge}) {
        @content;
    }
}

.user-setting {
    padding: 16px;
    height: 100%;

    .user-setting__title {
        font-size: 22px;
        text-transform: uppercase;
        color: var(--bs-gray-600);
        font-weight: 600;
    }

    .user-setting__body {
        width: 100%;
        height: calc(100% - 100px);
        margin-top: 16px;

        .user-setting__tabs {
            .tabs__tab {
                background-color: var(--bs-gray-200);
                color: var(--bs-gray-600);
                margin-right: 5px;

                &:hover {
                    background-color: var(--bs-gray-400);
                }

                &:not(:last-child) {}

                &--active {
                    font-weight: 600;
                    border-bottom: none;
                    background-color: var(--bs-regal-blue);
                    color: white;
                }

                @include LargeDevices {
                    // height: calc(100vh);
                }

                @include MediumDevices {
                    // 
                }

                @include SmallDevices {
                    width: 33.33%;
                    font-size: 10px;
                    padding: 5px;
                }

                @include XSmallDevices {
                    width: 33.33%;
                    font-size: 10px;
                    padding: 5px;
                }
            }
        }

        .user-setting__tab-panel {
            padding: 16px;
            background-color: white;
            height: 100%;
        }
    }

    .profile {
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        .profile__title {
            font-size: 16px;
            font-weight: 600;
            margin-top: 24px;

            &:not(:first-child) {
                margin-top: 16px;
            }

            @include LargeDevices {
                //
            }

            @include MediumDevices {
                margin-top: 0px;
            }

            @include SmallDevices {
                margin-top: 0px;
            }

            @include XSmallDevices {
                margin-top: 0px;
            }
        }

        .profile__avatar {
            width: 40%;
            padding: 8px;
            display: flex;
            justify-content: flex-start;
            align-items: center;

            @include LargeDevices {
                // height: calc(100vh);
            }

            @include MediumDevices {
                width: 100%;
            }

            @include SmallDevices {
                width: 100%;
                flex-direction: column;

            }

            @include XSmallDevices {
                width: 100%;
                flex-direction: column;
            }

            .avatar__image {
                width: 150px;
                height: 200px;
                border : 1px solid #c5c5c5;
                box-shadow: 5px 5px 10px rgba(104, 104, 104, 0.685);
            }

            .avatar__button {
                margin-left: 16px;
                align-self: flex-end;
                background-color: var(--bs-button-new);

                &:hover {
                    background-color: var(--bs-regal-blue);
                }
                &--success {
                    background-color: #34c38f;
                }

                &--danger {
                    background-color: #f46a6a;
                }

                @include LargeDevices {
                    //
                }

                @include MediumDevices {
                    //
                }

                @include SmallDevices {
                    margin-left: unset;
                    margin-top: 16px;
                }

                @include XSmallDevices {
                    margin-left: unset;
                    margin-top: 16px;
                }
            }
        }
    }

    .security {
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;

    }

    .advanced {
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        .advanced__title {
            font-size: 16px;
            font-weight: 600;
            margin-top: 24px;

            &:not(:first-child) {
                margin-top: 16px;
            }
        }

    }

    .form__input {
        width: 40%;
        margin-top: 16px;

        @include LargeDevices {
            width: 50%;
        }

        @include MediumDevices {
            width: 100%;
        }

        @include SmallDevices {
            width: 100%;
        }

        @include XSmallDevices {
            width: 100%;
        }

        &.--width-10 {
            width: 10%;

            @include LargeDevices {
                width: 15%;
            }

            @include MediumDevices {
                width: 100%;
            }

            @include SmallDevices {
                width: 100%;
            }

            @include XSmallDevices {
                width: 100%;
            }
        }
    }

    .form__button {
        margin-top: 16px;
        width: 40%;
        display: flex;
        justify-content: flex-end;

        .button {
            min-width: 120px;
            background-color: var(--bs-button-new);

            &:hover {
                background-color: var(--bs-regal-blue);
            }
        }
    }


}