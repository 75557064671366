.tag__content {
  padding: 15px;

  display: flex;

  &-leftline {
    width: 9px;
    background-color: var(--bs-stratos);
  }
  &-right {
    flex: 1 1 0;
    margin-left: 5px;

    display: flex;
    flex-direction: column;
  }
  &-title {
    font-size: 16px;
    font-weight: 600;
    line-height: 1;
  }

  &-data {
    padding-top: 5px;
  }
}

.memberlist__status {
  width: 100%;
  height: 851px;

  display: flex;
  flex-direction: column;
  padding: 15px;
}

.memberitem__status {
  height: 40px;
  width: 100%;

  padding-right: 10px;
  margin: 5px 0;
  display: flex;
  align-items: center;

  cursor: pointer;
  &:hover {
    background-color: rgb(228, 228, 228);
  }

  &-avatar {
    height: 32px;
    width: 32px;
    border-radius: 50%;
    margin-left: 5px;

    // background-image: url(https://picsum.photos/30/30);
    border: 1px solid var(--bs-gray-400);
  }
  &-name {
    margin-left: 10px;

    font-size: 16px;
    font-weight: 600;
    text-align: left;

    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  &-icon {
    height: 11px;
    width: 11px;
    border-radius: 50%;

    // justify-self: flex-end;
    margin-left: auto;
    border: 1px solid var(--bs-gray-400);
  }
}

.shared__searchfield {
  display: flex;
  flex-direction: row;

  &-main {
    flex: 1;
  }
  &-filter {
    width: 10%;
    height: 100%;

    border-left: 1px solid;
    padding-bottom: 6px;

    display: flex;
    justify-content: center;
    align-items: center;

    cursor: pointer;
  }

  &-popup {
    padding: 0 10px;

    display: flex;
    flex-direction: column;
  }
  &-popupClose {
    display: flex;
    justify-content: flex-end;
    // margin: 5px;

    cursor: pointer;
  }
  &-popupContent {
    padding: 0 5px;
    display: flex;
    flex-direction: column;
  }
}

.shared__userinfomation {
  width: 400px;
  padding: 10px 15px;
  display: flex;
  flex-direction: column;

  .userinformation__title {
    margin-bottom: 10px;
    text-align: center;

    font-size: 20px;
    font-weight: 600;
  }

  .userinformation__form {
    display: flex;
    flex-direction: column;

    &-section-01 {
      display: flex;
      flex-direction: row;

      .form__section01-col01 {
        width: 120px;

        img {
          width: 100%;
          height: 150px;

          object-fit: cover;
          border: 1px solid gray;
          border-radius: 5%;

          box-shadow: 5px 5px 10px rgba(189, 189, 189, 0.685);
        }

        .image-upload > input {
          display: none;
        }
      }

      .form__section01-col02 {
        flex: 1 1 0;
        margin-left: 15px;

        display: flex;
        flex-direction: column;

        justify-content: space-between;

        .MuiInputBase-root.Mui-disabled {
          color: rgb(61, 61, 61);
          cursor: not-allowed;
        }

        .MuiInputBase-input.Mui-disabled {
          cursor: not-allowed;
        }

        .css-nxo287-MuiInputBase-input-MuiOutlinedInput-input.Mui-disabled {
          -webkit-text-fill-color: black;
          opacity: 0.8;
        }
      }
    }

    &-section-02 {
      display: flex;
      flex-direction: row;

      justify-content: space-between;

      .form__section02-col01,
      .form__section02-col02 {
        width: 48%;
      }
      .css-jedpe8-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input.MuiSelect-select {
        white-space: pre-wrap;
        word-break: break-word;
      }

      .css-jedpe8-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input.Mui-disabled {
        -webkit-text-fill-color: black;
        opacity: 0.8;
      }

      .MuiInputBase-root.Mui-disabled {
        color: rgb(61, 61, 61);
        cursor: not-allowed;
      }

      .MuiInputBase-input.Mui-disabled {
        cursor: not-allowed;
      }
    }

    &-section-03 {
      .css-jedpe8-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input.MuiSelect-select {
        white-space: pre-wrap;
        word-break: break-word;
      }

      .MuiInputBase-root.Mui-disabled {
        color: rgb(61, 61, 61);
        cursor: not-allowed;
      }
      .MuiInputBase-input.Mui-disabled {
        cursor: not-allowed;
      }
      .MuiOutlinedInput-root {
        width: 100%;
      }

      .css-1auycx3-MuiAutocomplete-root
        .MuiOutlinedInput-root.MuiInputBase-sizeSmall {
        padding: 0px;
      }
    }

    &-section-04 {
      margin: 10px 0;
      display: flex;
      justify-content: space-between;
      .btn-dashboard {
        .css-sghohy-MuiButtonBase-root-MuiButton-root:nth-child(1) {
          background-color: var(--bs-success);
        }
        .btn-dashboard__hidden {
          display: none;
        }
      }
      .button {
        margin-top: 10px;

        &-delete {
          background-color: var(--bs-background-color-new) !important;
          color: var(--bs-stratos) !important;
          border: 1px solid var(--bs-bondi-blue) !important;
        }
        &-delete:hover {
          background-color: var(--bs-button-new) !important;
          color: white !important;
        }
        &-delete:disabled {
          color: gray !important;
        }

        &-save {
          background-color: var(--bs-button-new) !important;
        }
        &-save:hover {
          background-color: var(--bs-regal-blue) !important;
        }
      }
      .btn-ok {
        display: flex;
        justify-content: flex-end;

        .button {
          &-save {
            background-color: var(--bs-button-new) !important;
          }
          &-save:hover {
            background-color: var(--bs-regal-blue) !important;
          }
        }
      }
    }
    &-section-06 {
      margin: 10px 0;
      display: flex;
      justify-content: space-between;

      .button {
        &-delete {
          background-color: var(--bs-background-color-new) !important;
          color: var(--bs-stratos) !important;
          border: 1px solid var(--bs-bondi-blue) !important;
        }
        &-delete:hover {
          background-color: var(--bs-button-new) !important;
          color: white !important;
        }
        &-delete:disabled {
          color: gray !important;
        }

        &-save {
          margin-top: 10px;
          background-color: var(--bs-button-new) !important;
        }
        &-save:hover {
          background-color: var(--bs-regal-blue) !important;
        }
      }
    }
  }
}

.adview__userinfomation {
  width: 600px;

  padding: 10px 25px;
  display: flex;
  flex-direction: column;

  .userinformation__title {
    margin-bottom: 10px;
    text-align: center;

    font-size: 20px;
    font-weight: 600;
  }

  .userinformation__adform {
    display: flex;
    flex-direction: column;

    &-section-01 {
      display: flex;
      flex-direction: row;

      justify-content: space-between;

      .adform__section01-col01 {
        width: 32%;
        display: flex;
        justify-content: center;

        img {
          height: 150px;
          width: 125px;

          margin-left: 0%;
          object-fit: cover;
          border: 1px solid gray;
          border-radius: 5%;

          box-shadow: 5px 5px 10px rgba(189, 189, 189, 0.685);
        }

        .image-upload > input {
          display: none;
        }
      }

      .adform__section01-col02 {
        width: 32%;

        display: flex;
        flex-direction: column;
        justify-content: space-between;
      }
    }

    &-section-02 {
      display: flex;
      flex-direction: row;

      justify-content: space-between;

      .adform__section02-col01,
      .adform__section02-col02,
      .adform__section02-col03 {
        width: 32%;
      }

      .css-jedpe8-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input.MuiSelect-select {
        white-space: pre-wrap;
        word-break: break-word;
      }

      .css-jedpe8-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input.Mui-disabled {
        -webkit-text-fill-color: black;
        opacity: 0.8;
      }
    }

    &-section-03 {
      display: flex;
      flex-direction: row;

      justify-content: space-between;

      .adform__section03-col01 {
        width: 66%;
      }
      .adform__section03-col02 {
        width: 32%;
      }
      .MuiOutlinedInput-root {
        width: 100%;
      }
    }

    &-section-04 {
      width: 100%;
    }

    &-section-05 {
      display: flex;
      margin-top: 8px;
      margin-bottom: 8px;
      justify-content: flex-end;
    }

    &-section-06 {
      display: flex;
      justify-content: space-between;

      .adform__section06-col01 {
        width: 49%;
      }

      .css-jedpe8-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input.Mui-disabled {
        -webkit-text-fill-color: black;
        opacity: 0.8;
        color: black;
      }
    }
    &-section-07 {
      display: flex;
      margin-top: 8px;
      margin-bottom: 8px;

      justify-content: space-between;

      .button {
        &-delete {
          background-color: var(--bs-background-color-new) !important;
          color: var(--bs-stratos) !important;
          border: 1px solid var(--bs-bondi-blue) !important;
        }
        &-delete:hover {
          background-color: var(--bs-button-new) !important;
          color: white !important;
        }
        &-delete:disabled {
          color: gray !important;
          cursor: not-allowed !important;
        }

        &-save {
          background-color: var(--bs-button-new) !important;
        }
        &-save:hover {
          background-color: var(--bs-regal-blue) !important;
        }
      }
    }
  }
}

.shared__groupmanager {
  width: 400px;

  padding: 10px 15px;
  display: flex;
  flex-direction: column;

  .groupmanager__title {
    margin-bottom: 10px;
    text-align: center;

    font-size: 20px;
    font-weight: 600;
  }

  .groupmanager__form {
    display: flex;
    flex-direction: column;

    &-section-01 {
      display: flex;
      flex-direction: row;

      .form__section01-col01 {
        width: 120px;

        img {
          width: 100%;
          height: 150px;

          object-fit: cover;
          border: 1px solid gray;
          border-radius: 5%;

          box-shadow: 5px 5px 10px rgba(189, 189, 189, 0.685);
        }
      }

      .form__section01-col02 {
        flex: 1 1 0;
        margin-left: 15px;

        display: flex;
        flex-direction: column;

        justify-content: space-between;
      }
    }

    &-section-02 {
      display: flex;
      flex-direction: row;

      justify-content: space-between;

      .form__section02-col01,
      .form__section02-col02 {
        width: 48%;
      }

      .css-jedpe8-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input.MuiSelect-select {
        white-space: pre-wrap;
        word-break: break-word;
      }
    }

    &-section-04 {
      margin: 10px 0;
      display: flex;
      justify-content: space-between;
    }

    &-section-06 {
      margin: 15px 0 5px 0;
      display: flex;
      justify-content: flex-end;

      .btn-delete {
        .btn-delete__hidden {
          display: none;
        }
      }

      .btn-save {
        display: flex;
        justify-content: flex-end;

        .button--normal {
          background-color: var(--bs-button-new);
          &:hover {
            background-color: var(--bs-regal-blue);
          }
        }
      }
    }
  }

  .member {
    &__list {
      width: 100%;
      height: 100%;

      padding: 10px;
      display: flex;
      flex-direction: column;
    }
    &__header {
      width: 100%;
      display: flex;

      align-items: center;
      font-weight: 600;
    }
    &__content {
      width: 100%;
      height: 85%;
    }

    &__item {
      width: 100%;
      height: 30px;
      line-height: 30px;

      display: flex;
      justify-content: center;
      align-items: center;

      cursor: pointer;
      &:hover {
        background-color: rgb(196, 196, 196);
      }
    }
    &__footer {
      width: 100%;
      height: 35px;
      display: flex;

      justify-content: center;
    }

    &__col02 {
      width: 50%;
      height: 100%;

      text-align: left;
    }
    &__col01 {
      width: 25%;
      height: 100%;

      text-align: center;
    }
  }
}

.shared__confirm-popup {
  height: fit-content;
  width: 350px;

  padding: 10px;
  display: flex;
  flex-direction: column;

  .confirm-popup {
    &__tile {
      width: 100%;
      font-size: 30px;
      font-weight: 600;

      text-align: center;
    }

    &__icon {
      width: 100%;
      font-size: 90px;

      padding: 15px 0;
      display: flex;
      justify-content: center;
      align-items: center;

      i {
        color: orange;
      }
    }

    &__description {
      width: 100%;
      text-align: center;
      margin-bottom: 30px;
    }

    &__footer {
      width: 100%;
      display: flex;
      justify-content: space-between;

      .button--delete {
        background-color: var(--bs-button-new);

        &:hover {
          background-color: var(--bs-regal-blue);
        }
      }

      .button--cancel {
        background-color: var(--bs-background-color-new);
        color: var(--bs-stratos);
        border: 1px solid var(--bs-bondi-blue) !important;
        &:hover {
          background-color: var(--bs-button-new);
          color: white;
        }
      }
    }
  }
}

.shared__delete-popup {
  height: fit-content;
  width: 350px;

  padding: 10px;
  display: flex;
  flex-direction: column;

  .delete-popup {
    &__title {
      width: 100%;
      font-size: 30px;
      font-weight: 600;

      text-align: center;
    }

    &__icon {
      width: 100%;
      font-size: 90px;

      padding: 15px 0;
      display: flex;
      justify-content: center;
      align-items: center;

      i {
        color: red;
      }
    }

    &__description {
      width: 100%;
      text-align: center;
      margin-bottom: 30px;
    }

    &__footer {
      width: 100%;
      display: flex;
      justify-content: space-between;

      .button--delete {
        background-color: var(--bs-button-new);

        &:hover {
          background-color: var(--bs-regal-blue);
        }
      }

      .button--cancel {
        background-color: var(--bs-background-color-new);
        color: var(--bs-stratos);
        border: 1px solid var(--bs-bondi-blue) !important;
        &:hover {
          background-color: var(--bs-button-new);
          color: white;
        }
      }
    }
  }
}

.shared__success-popup {
  height: fit-content;
  width: 350px;

  padding: 10px;
  display: flex;
  flex-direction: column;

  .success-popup {
    &__title {
      width: 100%;
      font-size: 30px;
      font-weight: 600;

      text-align: center;
    }

    &__icon {
      width: 100%;
      font-size: 90px;

      padding: 15px 0;
      display: flex;
      justify-content: center;
      align-items: center;

      i {
        color: green;
      }
    }

    &__description {
      width: 100%;
      text-align: center;
      margin-bottom: 30px;
    }

    &__footer {
      width: 100%;
      display: flex;
      justify-content: flex-end;
    }
  }
}

//For Text Input Feild MUI
.MuiOutlinedInput-input {
  padding: 10.5px 14px !important;
}

@media (min-width: 360px) and (max-width: 639px) {
  .shared__userinfomation {
    width: 290px;
    padding: 10px;
  }

  .shared__confirm-popup {
    height: fit-content;
    width: 275px;
  }

  .shared__success-popup {
    height: fit-content;
    width: 275px;
  }
}

// Small devices (landscape phones, 640px and up)
@media (min-width: 640px) and (max-width: 767px) {
}

// Medium devices (tablets, 768px and up)
@media (min-width: 768px) and (max-width: 1199px) {
}

// Large devices I (large desktops, 1200px and up)
@media (min-width: 1200px) and (max-width: 1399px) {
}

// Large devices II (larger desktops, 1400px and up)
@media (min-width: 1400px) {
}
