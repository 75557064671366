.request {
  display: flex;
  flex-direction: column;

  height: 100%;
  width: 100%;
  padding: 15px;

  &__card {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;

    .card__content-button {
      flex-grow: 3;
      display: flex;
      flex-direction: column;
      align-items: flex-end;
      justify-content: flex-end;
      width: 64%;

      &-history-btn {
        background-color: var(--bs-background-color-new);
        color: var(--bs-stratos);
        border: 1px solid  var(--bs-bondi-blue) !important;
      }

      &-history-btn:hover{
        background-color: var(--bs-button-new);
        color: white;
      }
    }
    .card__content {
      display: flex;
      justify-content: space-between;

      &-number {
        display: flex;
        flex-grow: 1;
        line-height: 0.85;

        font-size: 90px;
        font-weight: 400;

        color: var(--bs-stratos);
      }
      &-days {
        flex-grow: 1;

        display: flex;
        align-items: flex-end;

        line-height: 0.85;

        font-weight: 400;
      }
      &-leave-content {
        flex-grow: 3;

        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-end;

        padding-left: 20%;

        line-height: 0.85;
        font-weight: 400;
      }
      &-leave-content > div {
        margin-top: 10%;
      }
    }
  }

  &__infor {
    display: flex;
    flex-direction: column;

    height: 100%;
    width: 45%;

    margin-top: 20px;
    &--input {
      display: flex;
      flex-direction: column;

      height: 100%;

      background-color: #fff;

      .leave-type {
        display: flex;
        flex-direction: column;

        padding: 15px 15px 0px 15px;

        &__select {
          .MuiOutlinedInput-root {
            width: 100%;
          }
        }
      }

      .reason {
        display: flex;
        flex-direction: column;

        padding: 15px 15px 0px 15px;

        .MuiFormControl-root {
          width: 100%;
        }
      }

      .datetime-picker {
        display: flex;
        justify-content: space-between;
        padding: 15px 15px 0px 15px;

        .date-picker {
          width: 55%;
        }
        .time-picker {
          width: 55%;
          margin-left: 20%;
        }
        .ant-picker {
          box-sizing: border-box;
          margin: 0;
          padding: 0;
          color: rgba(0, 0, 0, 0.85);
          font-size: 14px;
          font-variant: tabular-nums;
          line-height: 1.5715;
          list-style: none;
          font-feature-settings: "tnum", "tnum";
          padding: 4px 11px 4px;
          position: relative;
          display: inline-flex;
          align-items: center;
          background: #fff;
          border: 1px solid #d9d9d9;
          border-radius: 2px;
          transition: border 0.3s, box-shadow 0.3s;
        }

        .ant-picker-focused {
          border-color: #40a9ff;
          border-right-width: 1px !important;
          outline: 0;
        }

        .ant-picker-input {
          position: relative;
          display: inline-flex;
          align-items: center;
          width: 100%;
        }
      }

      .button {
        display: flex;
        justify-content: flex-end;
        padding: 30px 15px 30px 15px;

        &-submit {
          background-color: var(--bs-button-new);
        }
        &-submit:hover {
          background-color: var(--bs-regal-blue);
        }
      }
    }
  }
}
/****** RESPONSIVE ******/
// X-Small devices (portrait phones, 360px and up
@media only screen and (min-width: 360px) and (max-width: 639px) {
  .request {
    &__infor {
      display: flex;
      flex-direction: column;

      width: 100%;

      margin-top: 10px;
    }
    .tag__content {
      height: 100% !important;
      width: 100% !important;
    }
  }
}

// Small devices (landscape phones, 640px and up)
@media only screen and (min-width: 640px) and (max-width: 767px) {
  .request {
    &__infor {
      display: flex;
      flex-direction: column;

      width: 100%;

      margin-top: 10px;
    }
  }
}

// Medium devices (tablets, 768px and up)
@media only screen and (min-width: 768px) and (max-width: 1199px) {
  .request {
    &__infor {
      display: flex;
      flex-direction: column;

      width: 45%;

      margin-top: 10px;
    }

    .tag__content {
      height: 100% !important;
      width: 45% !important;
    }
  }
}

// Large devices I (large desktops, 1200px and up)
@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .request {
    &__infor {
      display: flex;
      flex-direction: column;

      width: 45%;

      margin-top: 10px;
    }
    .tag__content {
      height: 100% !important;
      width: 45% !important;
    }
  }
}

// // Large devices II (larger desktops, 1400px and up)
// @media (min-width: 1400px) {
//   .request {
//     &__infor {
//       display: flex;
//       flex-direction: column;

//       width: 100%;

//       margin-top: 10px;
//     }

//     .tag__content {
//       height: 100% !important;
//       width: 45% !important;
//     }
//   }
// }
