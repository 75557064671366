div iframe{
  width: 350px;
}

.login {
  &__background {
    width: 100%;
    height: 100vh;

    display: flex;
    align-items: center;
    justify-content: center;

    // background-color: rgb(61, 61, 61);
    background-color: rgba(83, 83, 83, 0.452);
    background: linear-gradient(
      0,
      rgba(114, 114, 114, 0.479),
      rgba(194, 194, 194, 0.418)
    );
  }

  &__card {
    width: 350px;
    height: 480px;
    background: white;

    display: flex;
    flex-direction: column;

    &-logo {
      width: 100%;
      height: 30px;
      margin: 20px 0;

      display: flex;
      justify-content: center;
      align-items: center;

      img {
        width: 30px;
        height: 30px;
        border-radius: 50%;
        margin-right: 5px;
        border: 2px solid gray;
      }
      p {
        font-size: 25px;
        font-weight: 600;
      }
    }
  }

  &__sign-form {
    width: 100%;
    height: 500px;
    padding: 25px;

    display: flex;
    flex-direction: column;
  }

  &__sign-account {
    width: 100%;
    height: 60%;

    display: flex;
    flex-direction: column;

    h5 {
      font-size: 14px;
      font-weight: 500;
      color: var(--bs-danger);
    }
  }

  &__sign-resetemail {
    width: 100%;
    height: 40%;

    display: flex;
    flex-direction: column;

    h6 {
      width: 100%;

      padding-top: 10px;
      color: var(--bs-success);
      text-align: center;
      font-size: 14px;
      font-weight: 400;
    }
  }
  &__sign-forget {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  &__sign-others {
    width: 100%;
    height: 40%;

    // display: flex;
    // flex-direction: column;
    align-items: center;

    .button{
      &-login {
        background-color: var(--bs-button-new) !important;
      }
      &-login:hover {
        background-color: var(--bs-regal-blue) !important;
      }
    }

    hr {
      margin: 15px 0;
    }

    P {
      width: 100%;

      padding: 10px 0;
      text-align: center;
      font-size: 14px;
    }
  }

  &__change-mode {
    font-size: 14px;
    cursor: pointer;
    color: blue;

    &:hover {
      color: var(--bs-red);
    }
  }

  //special for sign in form
  .MuiOutlinedInput-input {
    padding: 10.5px 14px !important;
  }
}
