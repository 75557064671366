.dashboard {
  height: 100%;
  width: inherit;

  padding: 15px;
  display: flex;

  &__left {
    flex: 1;
    padding-right: 15px;

    display: flex;
    flex-direction: column;

    &-section {
      width: 100%;
      display: flex;

      flex-direction: row;
      justify-content: space-between;
      .dashboard__left-col01 {
        flex: 1;
        padding-right: 15px;
        display: flex;
      }
      .dashboard__left-col02 {
        width: 480px;
      }
    }
  }
  &__time-tag {
    line-height: 0.85;
    font-size: 90px;
    font-weight: 400;

    color: var(--bs-stratos);
  }

  &__message {
    width: 300px;
    height: fit-content;

    // padding-bottom: 14px;
    // background-color: cadetblue;
  }

  &__leaves-tag {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    .leaves__tag-days {
      line-height: 0.85;
      font-size: 90px;
      font-weight: 400;

      color: var(--bs-stratos);
    }
  }

  &__noticeblock {
    width: fit-content;
    padding: 6px;
    margin: 5px 0;

    color: white;
  }

  &__calendar {
    width: 100%;
    height: 100%;

    display: flex;
    flex-direction: column;

    &-month {
      width: 100%;
      height: 50px;
      display: flex;

      justify-content: center;
      align-items: center;
      text-align: center;
      line-height: 50px;
      font-size: 20px;

      color: white;
      background-color: var(--bs-regal-blue);

      > div {
        font-weight: 600;
        margin: 0 25px;
      }

      > i {
        cursor: pointer;
        &:hover {
          transform: scale(1.1);
          color: var(--bs-danger);
        }
      }
    }
    &-date {
      display: flex;
      flex-direction: column;
      padding: 0 15px;
    }
    &-dayname {
      height: 30px;
      width: 100%;

      line-height: 30px;

      display: flex;
      flex-direction: row;
      justify-content: space-around;

      font-size: 16px;
      font-weight: 600;
      color: var(--bs-bondi-blue);
    }
    &-day {
      width: calc(100% / 7);
      text-align: center;
    }

    &-daydetail {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      border: 1px solid var(--bs-gray-500);
      // border-width: 1px 0px 0px 1px;

      box-shadow: 2px 2px 4px rgba(182, 182, 182, 0.438);
    }
  }

  &__dateitem {
    height: 45px;
    width: calc(100% / 7);

    display: flex;
    flex-direction: column;
    background-color: rgb(247, 247, 234) ;
    border-right: 1px solid var(--bs-gray-400);
    border-bottom: 1px solid var(--bs-gray-400);

    &:hover {
      background-color: rgb(224, 224, 216);
    }

    &-today {
      background-color: var(--bs-primary);
      color: white;
      &:hover {
        background-color: var(--bs-primary);
      }
    }

    &-number {
      height: 70%;
      text-align: center;
      font-size: 16px;
      line-height: 2.3;
      font-weight: 600;
    }
    &-notices {
      height: 30%;
      display: flex;
      align-items: center;
    }
    &-circle {
      height: 9px;
      width: 9px;
      border-radius: 50%;
      margin-left: 2px;
      box-shadow: 1px 1px 3px rgba(182, 182, 182, 0.438);
      cursor: pointer;
    }
  }
  &__popover {
    height: 200px;
    width: 180px;

    padding: 10px;
    display: flex;
    flex-direction: column;

    .dateitem-popover-row {
      width: 100%;

      margin-bottom: 3px;
    }
  }

  &__projectlist {
    width: 100%;
    height: 100%;

    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: flex-start;
  }
  &__pipechart {
    // flex: 1;
    width: 800px;
    height: 300px;
  }

  &__timesummary {
    // width: 400px;
    flex: 1;
    max-width: 465px;
    height: fit-content;

    display: flex;
    flex-direction: column;

    &-title {
      width: 100%;
      height: 50px;
      margin-bottom: 10px;

      display: flex;
      justify-content: space-around;

      font-size: 20px;
      font-weight: 600;
      background-color: var(--bs-gray-300);
      box-shadow: 2px 2px 5px rgba(128, 128, 128, 0.637);

      > div {
        width: calc(100% / 3);
        height: 100%;

        line-height: 50px;
        text-align: center;
      }
    }
    &-content {
      height: 250px;
      display: flex;

      background-color: var(--bs-gray-300);
      box-shadow: 2px 2px 5px rgba(128, 128, 128, 0.637);
    }

    &-time {
      width: calc(100% / 3);
      height: 100%;

      border-right: 1px solid gray;

      &:last-child {
        border-right: none;
      }
    }
  }
}

.column__time {
  height: 100%;
  width: 100%;

  margin-top: 20px;
  padding-right: 10px;

  display: flex;
  flex-direction: column;

  font-size: 60px;
  font-weight: 600;
  
  &-hours {
    text-align: right;
    text-shadow: 1px 2px rgba(173, 173, 173, 0.349);
    color: var(--bs-stratos) !important;
  }

  &-minutes {
    text-align: right;
    text-shadow: 1px 2px rgba(173, 173, 173, 0.349);
    color: var(--bs-stratos) !important;

  }
  &-decoration {
    display: inline-block;

    width: 25px;
    font-size: 25px;
    text-align: right;
  }
}
/******* RESPONSIVE ******/
// X-Small devices (portrait phones, 360px and up)
@media (min-width: 360px) and (max-width: 639px) {
  .dashboard {
    height: fit-content;
    &__left {
      padding-right: 0;
      &-section {
        flex-direction: column;
      }
      .dashboard__left-col01 {
        width: inherit;
        padding-right: 0;
        > div {
          width: 49% !important;
        }
      }
      .dashboard__left-col02 {
        padding-top: 15px;
        width: inherit;
      }
    }

    &__calendar {
      height: 228px !important;
      margin-top: 0px !important;
      &-month {
        height: 36px;
      }
      &-date {
        padding: 0 10px;
      }
      &-dayname {
        height: 20px;
        line-height: 20px;
      }
    }

    &__dateitem {
      height: 33px;
    }
  }
  .dashboard__message {
    width: 180px;
    height: 380px;
  }

  .tag__content {
    height: 90px !important;
  }
  .dashboard__leaves-tag .leaves__tag-days,
  .dashboard__time-tag {
    line-height: 0.85;
    font-size: 35px;

    .button-history {
      background-color: var(--bs-button-new);
    
      &:hover {
        background-color: var(--bs-regal-blue);
      }
    }
  }

  #dashboard__notice-id {
    width: inherit !important;
    height: 230px !important;
  }
  .dashboard__list-notices {
    height: 180px !important;
  }

  .memberlist__status {
    height: 390px;
  }

  .dashboard__timesummary {
    &-title {
      height: 40px;
      > div {
        line-height: 40px;
        font-size: 16px;
      }
    }

    &-content {
      height: 120px;
    }

    .column__time {
      margin-top: 5px;
      font-size: 28px;
    }
    .column__time-decoration {
      width: 16px;
      font-size: 16px;
    }
  }
  #dashboard__project-id {
    // height: 240px !important;
    height: max-content !important;
    width: inherit;
    margin-top: 0 !important;
  }

  .dashboard__pipechart {
    height: 200px;
    width: 250px !important;
  }
  .dashboard__timesummary {
    max-width: 280px !important;
  }
  #floating__member-list-id {
    position: fixed;
    right: 10px;
    bottom: 20px;
    opacity: 0.5;
  }
  .floating__member-list {
    width: 250px;
    height: 400px;
  }
}

// Small devices (landscape phones, 640px and up)
@media (min-width: 640px) and (max-width: 767px) {
  .dashboard {
    height: fit-content;
    &__left {
      &-section {
        flex-direction: column;
      }
      .dashboard__left-col01 {
        width: inherit;
        padding-right: 0;
        > div {
          width: 49% !important;
        }
      }
      .dashboard__left-col02 {
        padding-top: 15px;
        width: inherit;
      }
    }

    &__calendar {
      height: 228px !important;
      margin-top: 0px !important;
      &-month {
        height: 36px;
      }
      &-date {
        padding: 0 10px;
      }
      &-dayname {
        height: 20px;
        line-height: 20px;
      }
    }

    &__dateitem {
      height: 33px;
    }
  }
  .dashboard__message {
    width: 220px;
    height: 682px;
  }

  .tag__content {
    height: 90px !important;
  }
  .dashboard__leaves-tag .leaves__tag-days,
  .dashboard__time-tag {
    line-height: 0.85;
    font-size: 45px;
  }

  #dashboard__notice-id {
    width: inherit !important;
    height: 230px !important;
  }
  .dashboard__list-notices {
    height: 180px !important;
  }

  .memberlist__status {
    height: 680px;
  }

  .dashboard__timesummary {
    width: 300px;

    &-title {
      height: 40px;
      > div {
        line-height: 40px;
        font-size: 16px;
      }
    }

    &-content {
      height: 120px;
    }

    .column__time {
      margin-top: 5px;
      font-size: 35px;
    }
  }
  #dashboard__project-id {
    // height: 240px !important;
    height: max-content !important;
    width: inherit;
    margin-top: 0 !important;
  }

  .dashboard__pipechart {
    height: 170px;
    width: 500px !important;
  }
  .dashboard__timesummary {
    max-width: 330px !important;
  }
}

// Medium devices (tablets, 768px and up)
@media (min-width: 768px) and (max-width: 1199px) {
  .dashboard {
    height: fit-content;
    &__left {
      &-section {
        flex-direction: column;
      }
      .dashboard__left-col01 {
        width: inherit;
        padding-right: 0;
        > div {
          width: 49% !important;
        }
      }
      .dashboard__left-col02 {
        padding-top: 15px;
        width: inherit;
      }
    }

    &__calendar {
      height: 228px !important;
      margin-top: 0px !important;
      &-month {
        height: 36px;
      }
      &-date {
        padding: 0 10px;
      }
      &-dayname {
        height: 20px;
        line-height: 20px;
      }
    }

    &__dateitem {
      height: 33px;
    }
  }
  .dashboard__message {
    width: 220px;
    height: 682px;
  }

  .tag__content {
    height: 90px !important;
  }
  .dashboard__leaves-tag .leaves__tag-days,
  .dashboard__time-tag {
    line-height: 0.85;
    font-size: 45px;
  }

  #dashboard__notice-id {
    width: inherit !important;
    height: 230px !important;
  }
  .dashboard__list-notices {
    height: 180px !important;
  }

  .memberlist__status {
    height: 680px;
  }

  .dashboard__timesummary {
    width: 300px;

    &-title {
      height: 40px;
      > div {
        line-height: 40px;
        font-size: 16px;
      }
    }

    &-content {
      height: 120px;
    }

    .column__time {
      margin-top: 5px;
      font-size: 35px;
    }
  }
  #dashboard__project-id {
    // height: 240px !important;
    height: max-content !important;
    width: inherit;
    margin-top: 0 !important;
  }

  .dashboard__pipechart {
    height: 200px;
    width: 500px !important;
  }
  .dashboard__timesummary {
    max-width: 330px !important;
  }
}

// Large devices I (large desktops, 1200px and up)
@media (min-width: 1200px) and (max-width: 1399px) {
  .dashboard {
    &__left {
      .dashboard__left-col02 {
        width: 360px;
      }
    }

    &__calendar {
      height: 282px !important;
      &-month {
        height: 40px;
      }
      &-date {
        padding: 0 10px;
      }
    }

    &__dateitem {
      height: 40px;
    }
  }
  .dashboard__message {
    width: 220px;
    height: 682px;
  }

  .tag__content {
    height: 110px !important;
  }

  #dashboard__notice-id {
    height: 282px !important;
  }

  #dashboard__project-id {
    height: 260px !important;
  }

  .memberlist__status {
    height: 680px;
  }
  .dashboard__list-notices {
    height: 220px !important;
  }

  .dashboard__leaves-tag .leaves__tag-days,
  .dashboard__time-tag {
    line-height: 0.85;
    font-size: 65px;
  }

  .dashboard__timesummary {
    width: 300px;

    &-title {
      height: 40px;
      > div {
        line-height: 40px;
        font-size: 16px;
      }
    }

    &-content {
      height: 150px;
    }

    .column__time {
      font-size: 40px;
    }
  }
  .dashboard__pipechart {
    width: 500px;
    height: 220px;
  }
}

// Large devices II (larger desktops, 1400px and up)
@media (min-width: 1400px) {
}
