$XSmall: 360px;
$Small: 640px;
$Medium: 768px;
$Large: 1200px;
$XLarge: 1400px;

@mixin XSmallDevices {
    @media (min-width: #{$XSmall}) and (max-width: #{$Small - 1px}) {
        @content;
    }
}

@mixin SmallDevices {
    @media (min-width: #{$Small}) and (max-width: #{$Medium - 1px}) {
        @content;
    }
}

@mixin MediumDevices {
    @media (min-width: #{$Medium}) and (max-width: #{$Large - 1px}) {
        @content;
    }
}

@mixin LargeDevices {
    @media (min-width: #{$Large}) and (max-width: #{$XLarge - 1px}) {
        @content;
    }
}

@mixin XLargeDevices {
    @media (min-width: #{$XLarge}) {
        @content;
    }
}

.profile__avatar {
    width: 40%;
    padding: 8px;
    display: flex;
    justify-content: flex-start;
    align-items: center;

    @include LargeDevices {
        // height: calc(100vh);
    }

    @include MediumDevices {
        width: 100%;
    }

    @include SmallDevices {
        width: 100%;
        flex-direction: column;

    }

    @include XSmallDevices {
        width: 100%;
        flex-direction: column;
    }

    .avatar__image {
        width: 150px;
        height: 200px;
        border : 1px solid #c5c5c5;
        box-shadow: 5px 5px 10px rgba(104, 104, 104, 0.685);
    }

    .avatar__button {
        margin-left: 16px;
        align-self: flex-end;

        &--success {
            background-color: #34c38f;
        }

        &--danger {
            background-color: #f46a6a;
        }

        @include LargeDevices {
            //
        }

        @include MediumDevices {
            //
        }

        @include SmallDevices {
            margin-left: unset;
            margin-top: 16px;
        }

        @include XSmallDevices {
            margin-left: unset;
            margin-top: 16px;
        }
    }
}