.navigation {
  width: inherit;

  display: flex;
  flex-direction: column;
  align-items: center;

  &__logo {
    width: 100%;
    padding: 20px 0;
    margin-bottom: 30px;

    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;

    cursor: pointer;

    img {
      width: 32px;
      height: 32px;
      margin-right: 10px;

      border-radius: 50%;
      object-fit: cover;
    }
    p {
      font-size: 20px;
      font-weight: 600;
      margin: 0;
    }
  }

  &__member {
    width: 100%;

    display: flex;
    flex-direction: column;
  }
  &__admin {
    width: 100%;
    margin-top: 60px;

    border-top: 1px solid rgb(194, 194, 194);

    display: flex;
    flex-direction: column;

    > p {
      margin: 15px 0;
      margin-left: 20px;
      font-size: 18px;
      font-weight: 600;
    }
  }
}

@media (min-width: 360px) and (max-width: 639px) {
}

// Small devices (landscape phones, 640px and up)
@media (min-width: 640px) and (max-width: 767px) {
}

// Medium devices (tablets, 768px and up)
@media (min-width: 768px) and (max-width: 1199px) {
  .navigation__logo {
    margin-bottom: 0;
  }
}

// Large devices I (large desktops, 1200px and up)
@media (min-width: 1200px) and (max-width: 1399px) {
  .navigation__logo {
    margin-bottom: 0;
  }
}

// Large devices II (larger desktops, 1400px and up)
@media (min-width: 1400px) {
}
