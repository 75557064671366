.mainservice {
  width: 100vw;
  min-height: 100vh;
  display: flex;
  color: rgb(223, 223, 223);

  &__navigate {
    width: 250px;
    height: inherit;

    background-color: var(--bs-stratos);
  }

  &__right {
    min-height: 100%;
    flex: 1 1 0;
    display: flex;
    flex-direction: column;

    color: black;
  }

  &__topbar {
    height: 55px;
    border-bottom: 1px solid rgb(184, 184, 184);
  }

  &__mainview {
    background-color: #f8f8fb;
    height: 100%;
  }
}

/******* RESPONSIVE ******/
// X-Small devices (portrait phones, 360px and up)
@media (min-width: 360px) and (max-width: 639px) {
  .mainservice__navigate {
    width: 70px;
  }

  .navigation__logo,
  .navigation__item,
  .navigation__admin {
    p {
      visibility: hidden;
      width: 0px;
      height: 0;
      margin-left: 0;
    }

    img {
      margin-right: 0;
    }

    &-icon {
      display: flex;
      margin-left: 6px;
    }
  }

  .navigation__item {
    height: 28px;
    margin: 2px 0;

    padding-left: 0;
    justify-content: center;
  }

  .menutopbar__left {
    width: 0;
    visibility: hidden;
  }

  .navigation__logo {
    padding-top: 20px;
    padding-bottom: 20px;
    margin-bottom: 0px;
  }

  .navigation__admin {
    margin-top: 10px;
  }

  .menutopbar {
    padding: 0;
  }
}

// Small devices (landscape phones, 640px and up)
@media (min-width: 640px) and (max-width: 767px) {
  .mainservice__navigate {
    width: 70px;
  }

  .navigation__logo,
  .navigation__item,
  .navigation__admin {
    p {
      visibility: hidden;
      width: 0px;
      height: 0;
    }

    img {
      margin-right: 0;
    }

    &-icon {
      display: flex;
      margin-left: 6px;
    }
  }

  .navigation__item {
    height: 28px;
    margin: 2px 0;

    padding-left: 0;
    justify-content: center;
  }

  .navigation__logo {
    padding-top: 20px;
    padding-bottom: 20px;
    margin-bottom: 0px;
  }

  .navigation__admin {
    margin-top: 10px;
  }

  .menutopbar {
    padding: 0;
    padding-left: 15px;
  }
}

// Medium devices (tablets, 768px and up)
@media (min-width: 768px) and (max-width: 1199px) {
  .mainservice__navigate {
    width: 200px;
  }
}

// Large devices I (large desktops, 1200px and up)
@media (min-width: 1200px) and (max-width: 1399px) {}

// Large devices II (larger desktops, 1400px and up)
@media (min-width: 1400px) {}