.navbar__column button {
  color: var(--bs-bondi-blue);
}

.admin__overtime {
  height: 100%;
  width: 100%;

  padding: 15px;
  padding-top: 5px;

  display: flex;
  flex-direction: column;

  &--content {
    height: 100%;
  }

  &--requesttab {
    height: 100%;
    .requesttab__filter {
      &--condition {
        display: flex;
        margin: 15px 0px;
        width: 100%;

        .status-selection {
          display: flex;
          flex-grow: 1;
        }

        .button--calculate {
          background-color: var(--bs-button-new);

          &:hover {
            background-color: var(--bs-regal-blue);
          }
        }
      }
    }

    .requesttab__table {
      background-color: white;

      padding: 15px;
      height: 92%;

      display: flex;
      align-items: center;
      justify-content: center;

      .MuiDataGrid-header {
        background-color: var(--bs-regal-blue);
        border-right: 1px solid rgb(32, 30, 30);
      }
      .MuiDataGrid-header:first-child {
        border-left: 1px solid rgb(32, 30, 30);
      }

      .MuiDataGrid-columnHeaders {
        border-top: 1px solid black;
        border-bottom: 1px solid black;
      }

      .MuiDataGrid-columnHeaderTitle {
        font-weight: bold;
        color: white;
      }
      .MuiDataGrid-virtualScroller {
        font-weight: 650;
      }
      .MuiDataGrid-tablecell {
        border-right: 1px solid rgb(32, 30, 30);
        border-bottom: 1px solid rgb(32, 30, 30) !important;
      }
      .MuiDataGrid-tablecell:first-child {
        border-left: 1px solid rgb(32, 30, 30);
      }
      .MuiDataGrid-columnSeparator {
        visibility: hidden;
      }
      .MuiFormControl-fullWidth {
        width: 120px;
      }
      .status--approved {
        color: var(--bs-approved);
      }

      .status--rejected {
        color: var(--bs-rejected);
      }

      .status--pending {
        color: var(--bs-pending);
      }

      .weekday {
        color: var(--bs-weekday);
      }
      .weekend {
        color: var(--bs-weekend);
      }
      .night-overtime {
        color: var(--bs-night-overtime);
      }
      .public-holiday {
        color: var(--bs-public-holiday);
      }
    }
  }
}
