$XSmall: 360px;
$Small: 640px;
$Medium: 768px;
$Large: 1200px;
$XLarge: 1400px;

@mixin XSmallDevices {
  @media (min-width: #{$XSmall}) and (max-width: #{$Small - 1px}) {
    @content;
  }
}

@mixin SmallDevices {
  @media (min-width: #{$Small}) and (max-width: #{$Medium - 1px}) {
    @content;
  }
}

@mixin MediumDevices {
  @media (min-width: #{$Medium}) and (max-width: #{$Large - 1px}) {
    @content;
  }
}

@mixin LargeDevices {
  @media (min-width: #{$Large}) and (max-width: #{$XLarge - 1px}) {
    @content;
  }
}

@mixin XLargeDevices {
  @media (min-width: #{$XLarge}) {
    @content;
  }
}

.calendar-picker__wrap {
    height: 100%;
    display: flex;
    align-items: center;
    color: #ffff;

    .calendar-picker__button {

        &:hover {
            background-color: var(--bs-bondi-blue);
        }

        .button__icon {
            color: #ffff;
            font-size: 23px;

            @include LargeDevices {
                font-size: 20px;
            }

            @include MediumDevices {
                font-size: 18px;
            }

            @include SmallDevices {
                font-size: 18px;
            }

            @include XSmallDevices {
                font-size: 18px;
            }
        }
    }

    .button__icon {
      color: #a6b0cf;
      font-size: 23px;

      @include LargeDevices {
        font-size: 20px;
      }

      @include MediumDevices {
        font-size: 18px;
      }

      @include SmallDevices {
        font-size: 18px;
      }

      @include XSmallDevices {
        font-size: 18px;
      }
    }
  }

  .calendar-picker__text {
    text-align: center;
    width: 210px;
    font-weight: 700;
    font-size: 23px;
    text-transform: uppercase;
    user-select: none;

    @include LargeDevices {
      font-size: 21px;
      width: 180px;
    }

    @include MediumDevices {
      font-size: 16px;
      height: 50px;
      width: 150px;
      display: flex;
      align-items: center;
    }

    @include SmallDevices {
      font-size: 16px;
      height: 50px;
      width: 100px;
      display: flex;
      align-items: center;
    }

    @include XSmallDevices {
      font-size: 16px;
      height: 50px;
      width: 70%;
      display: flex;
      align-items: center;
    }
  }

  // .calendar-picker-popup__wrap {
  //     display: flex;
  //     flex-wrap: wrap;

  //     :not(style) {
  //         margin: 1px;
  //         height: auto;
  //         position: absolute;
  //     }
  // }

  // .calendar-picker-popup {
  //     width: 300px;
  // }

  .box--none-display {
    width: 0;
    height: 0;
    line-height: 0;
    background: none;
    border: none;
    padding: 0;
    margin: 0px;
    color: var(--bs-gray-200);

    &:hover {
      background: inherit;
      border-color: inherit;
    }

    &:focus {
      outline: none;
      width: 0;
    }
  }
